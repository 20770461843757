import React,{Component} from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Http from 'axios'
import StudentCard from './StudentCard'
import '../Auth/Auth.css'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Login from '../Auth/Login'



class Dashboard extends Component{
	constructor(){
		super();
		this.state={isLogged:false,isLoaded:false, users:[]};
	}
	async componentWillMount() {
		if(this.props.user)
		{
	           sessionStorage.setItem('user',JSON.stringify(this.props.user));
		   console.log(this.props.user);
	           this.state.user=this.props.user;
                   this.state.isLogged=true;
		}
		else if(this.state.user=JSON.parse(sessionStorage.getItem('user')))
                this.state.isLogged=true;
		else{
		console.log("User not Logged in");
                this.state.isLogged=false;
		}
	}
	async componentDidMount() {
	        var api;
    Http.defaults.headers.common['Authorization'] = this.state.user.token;
		if(this.state.isLogged)
		{
		    api='user-api/user';
		    const response = await Http.get(api);
                    this.setState({ isLoaded:true, users:response.data });
		}
	}
	toLocalLast = (last_login) => {
	       if(last_login=="")
	       return("Exam Not Given");
           var x=new Date(Date.parse(last_login.slice(0, 19).replace(' ', 'T')+'Z'));
           return(x.toLocaleString());
        }
	render() {
	if(this.state.isLogged){
	return(
		
		<div>
                <nav className="navbar">
                <nav className="navbar-brand" style={{position:"absolute"}}>
	        	<h3><a style={{color:"#fafafa"}} href="/"><img src="/monitorexam_t.png" width="213px" height="120px"></img></a></h3>
                </nav>
                  <nav className="navbar me-auto appmenu right">
                    <a className="nav-link" style={{color:"#00b3b3"}} href="/examdash"> Exam Dashboard</a>
                    <a className="nav-link" href="/login" style={{backgroundColor:"#00b3b3",color:"white",borderRadius:"30px"}} onClick={()=>sessionStorage.clear()}>Logout</a>
                  </nav>
                </nav>
		<br />
		<br />
		<br />
		<br />
		<h4 style={{backgroundColor:"#00b3b3",color:"white",padding:"8px"}}>{this.state.user.first_name}'s Student Dashboard</h4>
		<div className="row invite">
		<div className="column side">
		</div>
		<div className="column middle">
		<div className="row" style={{marginLeft:"0px",marginRight:"0px",flexFlow:"rowReverse"}}>
                <div style={{display:this.state.user.role=='invig'?'block':'none'}}><a className="float-right btn btn-primary" style={{display:this.state.user.role=='invig'?'block':'none',backgroundColor:"#00b3b3",color:"white"}} href="/invite">Invite Student</a></div>
		</div>
		<br />
		<div>
		<Card>
		<Card.Title>All Students</Card.Title>
		<Card.Body>
		<table style={{borderSpacing:"5px",width:"100%"}} class="table-responsive table" >

		    <tr style={{paddingLeft:"15px", textAlign:"centre"}}><th> #</th><th></th><th style={{padding:"15px"}}> Name </th><th style={{padding:"15px"}}> Class </th><th style={{padding:"15px"}}> Mobile</th><th>Email</th><th>Last Seen</th></tr>
		<>
		{this.state.users.filter(user=>(user.invig_id==this.state.user.id)).map(user=>( <tr style={{padding:"15px",textAlign:"left"}}><td> {user.id}</td><td><img src={'http://d1bumj8k6ax755.cloudfront.net/uploads/'+user.reg_pic.split("'")[1]} class="limsthumb" style={{width:"100px",borderRadius:"50%"}} alt="no pic"/></td><td> {user.first_name+" "+ user.last_name}</td><td>{user.class}</td><td><a style={{color:"black"}} href={"tel:"+user.mobile}>{user.mobile}</a></td>
			<td>{user.email}</td><td>{this.toLocalLast(user.last_login)}</td>
			</tr>))}
		</>
		</table>
		</Card.Body>
		</Card>
		</div>
		</div>
		</div>
		</div>
	);
	}
	else {
		return(<Login />);
	}
	}
}

export default Dashboard
