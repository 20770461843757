import React, {Component} from 'react'
import Profile from './NewProfile'
import Button from 'react-bootstrap/Button'

/* User Verification process */
class NewWait extends React.Component{
	constructor(){
		super();
		var x=new Date();
		this.state={ 
			time:x,
			offset:x.getTimezoneOffset(),
			state:"Unset",
				userMenu:false,
				menu:false
			};
		this.toggleMenu=this.toggleMenu.bind(this);
		this.toggleUserMenu=this.toggleUserMenu.bind(this);
	}
	//standard function. Assign value to name
	    handleInput = (event) => {
		let nam = event.target.name;
		let val = event.target.value;
		this.setState({
		    [nam]: val
		});
		if(nam=="exam_id")
		  console.log(this.state.exam_id)
	    }
	componentWillMount(){
		console.log("Wait Component");
                const user = JSON.parse(sessionStorage.getItem('user'));
                const exam = JSON.parse(sessionStorage.getItem('exam1'));
        if (user)
            this.state.user = user;
        else if (this.props.user)
            this.state.user = this.props.user;
        else
            console.log("User not available");
        //TODO: May be add login again to above
        
        if (exam)
          this.state.exam = exam;
        else if (this.props.exam)
          this.state.exam = this.props.exam;
        else
        {
          console.log("Exam not available");
          this.state.exam_found=0;
        } 
	if(this.state.exam_found!=0) {
		console.log(this.state.exam);
		var state=((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z"))<(Date.now()))?(((Date.parse(this.state.exam.date)+Date.parse("1970-01-01T"+this.state.exam.start_time.toString()+"Z")+this.state.exam.duration*60000)>(Date.now()))?"OnTime":"Late"):"Early";
		this.state.state=state;
		console.log(this.state.state);
		this.interval = setInterval(()=>this.setState({time:Date.now()}),1000);
	  }
	}
	componentDidUnmount(){
		clearInterval(this.interval);
	}
	toggleUserMenu() {
                this.setState({userMenu:!this.state.userMenu});  
	}
	toggleMenu() {
                this.setState({menu:!this.state.menu});  
	}
	toLocal = (utctime) => { var x=new Date(Date.parse("1970-01-01T"+utctime.toString()+"Z"));x+=this.state.user.timezone*60000;return(x.toLocaleString().slice(16,24));}

  render() {
	  const show = this.state.menu ? "active" : "";
	  const showMenu = this.state.menu ? "content-active" : "";
	  const usershow = this.state.userMenu ? "show" : "";
	if(this.state.exam_found==0) {
	  return(
	    <div>
		  {/*<label for="exam_id"> Please enter exam id received in mail </label>
	      <input placeholder="XXXXXXXX" id="exam_id" name="exam_id" onClick={this.updateState}> </input>*/}
		  <label for="exam_id"> Please enter exam id received in mail </label>
		  <input type="text" id="exam_id" name="exam_id" placeholder="XXXXXXXXX" onChange={this.handleInput} />
		  <Button onClick = {this.getExam}>Start Test</Button>
	    </div>);
	} else if(this.state.state=="Early")
    return (
      <div>
        <meta charSet="utf-8" />
        <meta name="author" content="Softnio" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="description" content="@@page-discription" />
        {/* Fav Icon  */}
        <link rel="shortcut icon" href="images/favicon.png" />
        {/* Page Title  */}
        <title>Verification Room | Monitor Exam</title>
        {/* StyleSheets  */}
        <link rel="stylesheet" href="assets/css/style.css" />
        <link rel="stylesheet" href="assets/css/custom.css" />
        <link id="skin-default" rel="stylesheet" href="assets/css/theme.css?ver=1.2.0" />
        <div className="nk-app-root">
          {/* main @s */}
          <div className="nk-main ">
            {/* wrap @s */}
            <div className="nk-wrap ">
              <div className="nk-header nk-header-fixed is-light">
                <div className="container-lg wide-xl">
                  <div className="nk-header-wrap">
                    <div className="nk-header-brand">
                      <a href="#" className="logo-link">
                        <img className="logo-dark logo-img" src="images/monitor-exam-logo.png" srcSet="images/monitor-exam-logo-2x.png 2x" alt="logo-dark" />
                      </a>
                    </div>{/* .nk-header-brand */}
                    <div className="nk-header-tools">
                      <ul className="nk-quick-nav">
                        <li onClick={this.toggleUserMenu} className={"dropdown user-dropdown " + usershow}>
                          <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded={this.state.userMenu}>
                            <div className="user-toggle">
                              <div className="user-avatar sm">
                                <img src={'https://d1bumj8k6ax755.cloudfront.net/uploads/' + this.state.user.reg_pic.split("'")[1]} class="regpic " alt="reg" style={{ transform: "scale(0.5)", left: "6px" }} />
                                <em className="icon ni ni-user-alt" />
                              </div>
                              <div className="user-name dropdown-indicator d-none d-sm-block">{this.state.user.first_name} {this.state.user.last_name}</div>
                            </div>
                          </a>
                          <div className={"dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 "+usershow}>
                            <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                              <div className="user-card">
                                <div className="user-avatar">
                                  <span>AB</span>
                                </div>
                                <div className="user-info">
                                  <span className="lead-text">{this.state.user.first_name} {this.state.user.last_name}</span>
                                  <span className="sub-text">{this.state.user.email}</span>
                                </div>
                                <div className="user-action">
                                  <a className="btn btn-icon mr-n2" href="#"><em className="icon ni ni-setting" /></a>
                                </div>
                              </div>
                            </div>
                            <div className="dropdown-inner">
                              <ul className="link-list">
                                <li><a href="/viewprofile"><em className="icon ni ni-user-alt" /><span>View Profile</span></a></li>
                                <li><a href="/security"><em className="icon ni ni-setting-alt" /><span>Security Setting</span></a></li>
                                <li><a href="#"><em className="icon ni ni-activity-alt" /><span>Login Activity</span></a></li>
                              </ul>
                            </div>
                            <div className="dropdown-inner">
                              <ul className="link-list">
                                <li><a href="\newlogin"><em className="icon ni ni-signout" /><span onClick={()=>sessionStorage.clear()}>Sign Out</span></a></li>
                              </ul>
                            </div>
                          </div>
                        </li>{/* .dropdown */}
                        <li onClick={this.toggleMenu} className="d-lg-none">
                          <a href="#" className={"toggle nk-quick-nav-icon mr-n1 "+this.state.menu?"active":""} data-target="sideNav"><em className="icon ni ni-menu" /></a>
                        </li>
                      </ul>{/* .nk-quick-nav */}
                    </div>{/* .nk-header-tools */}
                  </div>{/* .nk-header-wrap */}
                </div>{/* .container-fliud */}
              </div>
              {/* main header @e */}
              {/* content @s */}
              <div className="nk-content ">
                <div className="container wide-xl">
                  <div className="nk-content-inner">
                    <div className={"nk-aside "+showMenu} data-content="sideNav" data-toggle-overlay="true" data-toggle-screen="lg" data-toggle-body="true">
                      <div className="nk-sidebar-menu" data-simplebar>
                        {/* Menu */}
                        <ul className="nk-menu">
                          <li className="nk-menu-heading">
                            <h6 className="overline-title">Menu</h6>
                          </li>
                          <li className="nk-menu-item">
                            <a href="\newdashboard" className="nk-menu-link">
                              <span className="nk-menu-icon"><em className="icon ni ni-users" /></span>
                              <span className="nk-menu-text">Student DashBoard</span>
                            </a>
                            <ul className="nk-menu-sub">
                              <li className="nk-menu-item "><a href="\livestudentdash" className="nk-menu-link"><span className="nk-menu-text">Live Students</span></a></li>
                              <li className="nk-menu-item "><a href="\newdashboard" className="nk-menu-link"><span className="nk-menu-text">All Students</span></a></li>
		            </ul>
                          </li>
                          <li className="nk-menu-item active">
                            <a href="\newexamdash" className="nk-menu-link">
                              <span className="nk-menu-icon"><em className="icon ni ni-file-docs" /></span>
                              <span className="nk-menu-text">Exam Dashboard</span>
                            </a>
                            <ul className="nk-menu-sub">
                              <li className="nk-menu-item"><a href="#ongoing" className="nk-menu-link"><span className="nk-menu-text">Ongoing Exams</span></a></li>
                              <li className="nk-menu-item"><a href="#upcoming" className="nk-menu-link"><span className="nk-menu-text">Upcoming Exams</span></a></li>
                              <li className="nk-menu-item"><a href="#unscheduled" className="nk-menu-link"><span className="nk-menu-text">Unscheduled Exams</span></a></li>
                              <li className="nk-menu-item"><a href="#other" className="nk-menu-link"><span className="nk-menu-text">Past Exams </span></a></li>
                            </ul>
                          </li>
                          <li className="nk-menu-item">
                            <a href="\newlogin" className="nk-menu-link">
                              <span className="nk-menu-icon"><em className="icon ni ni-signout" /></span>
                              <span onClick={()=>sessionStorage.clear()} className="nk-menu-text">Log Out</span>
                            </a>
                          </li>
                        </ul>
                      </div>{/* .nk-sidebar-menu */}
                      <div className="nk-aside-close">
                        <a href="#" className="toggle" data-target="sideNav"><em className="icon ni ni-cross" /></a>
                      </div>{/* .nk-aside-close */}
                    </div>{/* .nk-aside */}
                    <div className="nk-content-body">
                      <div className="nk-content-wrap">
                        <div className="nk-block-head nk-block-head-lg">
                          <div className="nk-block-between-md g-4">
                            <div className="nk-block-head-content">
                              <h2 className="nk-block-title">Upload The Document</h2>
                              <div className="nk-block-des">
                                <p>Complete your verification in 3 simple steps</p>
                              </div>
                            </div>
                            <div className="nk-block-head-content">
                              <ul className="nk-block-tools gx-3">
                                <li />
                              </ul>
                            </div>
                          </div>
                        </div>{/* .nk-block-head */}
                        <div className="nk-block">
                          <div className="card card-bordered verification-box room">
                            <h4 className="text-primary text-center mb-4">Verification Room</h4>
                            <div className="row step-card">
                              <div className="col-sm-4 step-1">
                                <div className="card card-bordered p-2 mb-2">
                                  <span className="text-center mb-2">Step 1: Click Picture</span>
                                  <a href="#" className="btn btn-primary btn-block mb-2">Capture</a>
                                  <img src="images/avatar/b-sm.jpg" className="mb-2" />
                                  <span className="badge badge-simple text-center">Photo Valid</span>
                                </div>
                              </div>
                              <div className="col-sm-4 step-2">
                                <div className="card card-bordered p-2 mb-2">
                                  <span className="text-center mb-2">Step 2: Verify Enviroment</span>
                                  <a href="#" className="btn btn-primary btn-block mb-2">Update State</a>
                                  <img src="images/map.jpg" className="mb-2" />
                                  <p><em className="icon ni ni-camera text-primary" /> .... <span className="ok"><em className="icon ni ni-check" /></span> </p>
                                  <p><em className="icon ni ni-spark text-primary" /> .... <span className="ok"><em className="icon ni ni-check" /></span> <span className="status">Level 74% Not Charging</span></p>
                                  <p><em className="icon ni ni-speed text-primary" /> .... <span className="ok"><em className="icon ni ni-check" /></span> <span className="status">Network speed is 3.55 Mbps</span></p>
                                  <p><em className="icon ni ni-location text-primary" /> .... <span className="ok"><em className="icon ni ni-check" /></span> <span className="status">182.76.28.67</span></p>
                                </div>
                              </div>
                              <div className="col-sm-4 step-3">
                                <div className="card card-bordered p-2">
                                  <span className="text-center mb-2">Step 3: Upload Admit Card</span>
                                  <a href="#" className="btn btn-primary btn-block mb-2">Upload Admit Card</a>
                                  <img src="images/avatar/b-sm.jpg" className="mb-2" />
                                  <span className="badge badge-simple text-center">Admit Card Valid</span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-sm-12 text-right">
                                <a href="verification-start.html" className="btn btn-primary mb-3 mt-3 nextpage">Start Verification</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* footer @e */}
                    </div>
                  </div>
                </div>
              </div>
              {/* content @e */}
            </div>
            {/* wrap @e */}
          </div>
          {/* main @e */}
        </div>
        {/* app-root @e */}
        {/* JavaScript */}
      </div>
    );
		else
			return(<Profile user={this.state.user}/>);
  }
}

export default NewWait
