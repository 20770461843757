import React, {Component} from 'react'
import Http from 'axios'
import {
    Redirect
} from 'react-router-dom'
import NewDashboard from '../Dashboard/NewDashboard'
import NewExamDashboard from '../Dashboard/NewExamDashboard'
import AdminDashboard from '../Dashboard/AdminDashboard'

class NewInvite extends React.Component{
	constructor(){
        	super();
		this.state = {
                status: false,
                menu:false
                };	
		this.toggleMenu=this.toggleMenu.bind(this);
		this.toggleUserMenu=this.toggleUserMenu.bind(this);
	}
	handleChange = (event)=>{
		let nam=event.target.name;
		let val=event.target.value;
		this.setState({[nam]:val});
	}
        componentWillMount() {
    
    	const user = JSON.parse(sessionStorage.getItem('user'));
        if (user)
            this.state.user = user;
        else if (this.props.user)
            this.state.user = this.props.user;
        else
            console.log("User not available");
            this.setState({
                status: true,
                testStarted: false,
                camera_state: this.state.user.camera_state,
                network_state: this.state.user.network_state,
                battery_state: this.state.user.battery_state,
                battery_level: this.state.user.battery_level,
                cred: this.state.user.cred,
                ip: this.state.user.ip,
                captured: this.state.user.captured,
                live_pic:this.state.user.live_pic,
                reg_pic:this.state.user.reg_pic
            });
         console.log(this.state.user);
        }
	handleSubmit = (event)=>{
                const errorMsgElement = document.querySelector('span#errorMsg');
		this.setState({user:JSON.parse(sessionStorage.getItem('user'))});
                Http.defaults.headers.common['Authorization'] = this.state.user.token;
		const x=new Date();
		event.preventDefault();
		        Http.post('user-api/useri',{
		          "first_name":this.state.first_name,
		          "last_name":this.state.last_name,
		          "email":this.state.email,
		          "mobile":this.state.mobile,
				  "invig_id":Number(this.state.user.id)
		        }, {
		          headers: {
		            "Content-Type": "application/json"
		          }
		        })
		      .then(res => {
                      const response = res.data;
                      //this.setState({ user:response,status:false});
		             //sessionStorage.setItem('user',JSON.stringify(response)); 
		            errorMsgElement.innerHTML=this.state.first_name+" invited successfully";
		            errorMsgElement.innerHTML=response;
		            console.log(this.state.first_name+" invited successfully");
	                return <Redirect to="/profile" />
                       })
                .catch(function(error) {
                    errorMsgElement.innerHTML="Invitation to "+this.state.first_name+" failed. Try sending again";
                    errorMsgElement.innerHTML = error;
                    console.log(error);
                });
	}
	toggleUserMenu() {
		this.setState({userMenu:!this.state.userMenu});  
	}
	toggleMenu() {
		this.setState({menu:!this.state.menu});  
	}



render() {
const show = this.state.menu ? "active" : "";
const showMenu = this.state.menu ? "content-active" : "";
const usershow = this.state.userMenu ? "show" : "";
return(
<div>
<meta charSet="utf-8" />
<meta name="author" content="Softnio" />
<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
<meta name="description" content="@@page-discription" />
{/* Fav Icon  */}
<link rel="shortcut icon" href="../images/favicon.png" />
{/* Page Title  */}
<title>Registration | Monitor Exam</title>
{/* StyleSheets  */}
<link rel="stylesheet" href="../assets/css/style.css?ver=1.2.0" />
<link rel="stylesheet" href="../assets/css/custom.css" />
<link id="skin-default" rel="stylesheet" href="../assets/css/theme.css?ver=1.2.0" />
{/*Header*/}
{/* app body @s */}
<div className="nk-app-root">
	<div className="nk-main ">
        {/* wrap @s */}
            <div className="nk-wrap ">
              <div className="nk-header nk-header-fixed is-light">
                <div className="container-lg wide-xl">
                  <div className="nk-header-wrap">
                    <div className="nk-header-brand">
                      <a href="/" className="logo-link">
                        <img className="logo-dark logo-img" src="images/monitor-exam-logo.png" srcSet="images/monitor-exam-logo-2x.png 2x" alt="logo-dark" />
                      </a>
                    </div>{/* .nk-header-brand */}
                    <div className="nk-header-tools">
                      <ul className="nk-quick-nav">
                        <li onClick={this.toggleUserMenu} className={"dropdown user-dropdown " + usershow}>
                          <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded={this.state.userMenu}>
                            <div className="user-toggle">
                              <div className="user-avatar sm">
                                <img src={'https://d1bumj8k6ax755.cloudfront.net/uploads/' + this.state.user.reg_pic.split("'")[1]} class="regpic " alt="reg" style={{ transform: "scale(0.5)", left: "6px" }} />
                                <em className="icon ni ni-user-alt" />
                              </div>
                              <div className="user-name dropdown-indicator d-none d-sm-block">{this.state.user.first_name} {this.state.user.last_name}</div>
                            </div>
                          </a>
                          <div className={"dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 "+usershow}>
                            <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                              <div className="user-card">
                                <div className="user-avatar">
                                  <span>AB</span>
                                </div>
                                <div className="user-info">
                                  <span className="lead-text">{this.state.user.first_name} {this.state.user.last_name}</span>
                                  <span className="sub-text">{this.state.user.email}</span>
                                </div>
                                <div className="user-action">
                                  <a className="btn btn-icon mr-n2" href="#"><em className="icon ni ni-setting" /></a>
                                </div>
                              </div>
                            </div>
                            <div className="dropdown-inner">
                              <ul className="link-list">
                                <li><a href="\viewprofile"><em className="icon ni ni-user-alt" /><span>View Profile</span></a></li>
                                <li><a href="/security"><em className="icon ni ni-setting-alt" /><span>Security Setting</span></a></li>
                                <li><a href="#"><em className="icon ni ni-activity-alt" /><span>Login Activity</span></a></li>
                              </ul>
                            </div>
                            <div className="dropdown-inner">
                              <ul className="link-list">
                                <li><a href="#"><em className="icon ni ni-signout" /><span onClick={()=>sessionStorage.clear()}>Sign out</span></a></li>
                              </ul>
                            </div>
                          </div>
                        </li>{/* .dropdown */}
                        <li onClick={this.toggleMenu} className="d-lg-none">
                          <a href="#" className={"toggle nk-quick-nav-icon mr-n1 "+this.state.menu?"active":""} data-target="sideNav"><em className="icon ni ni-menu" /></a>
                        </li>
                      </ul>{/* .nk-quick-nav */}
                    </div>{/* .nk-header-tools */}
                  </div>{/* .nk-header-wrap */}
                </div>{/* .container-fliud */}
              </div>
              {/* main header @e */}
              {/* content @s */}
        <div className="nk-content">

  <div className="nk-split nk-split-page nk-split-md">
	        <div className={"nk-aside "+showMenu}  data-content="sideNav" data-toggle-overlay="true" data-toggle-screen="lg" data-toggle-body="true">
                      <div className="nk-sidebar-menu" data-simplebar>
                        {/* Menu */}
                        <ul className="nk-menu">
                          <li className="nk-menu-heading">
                            <h6 className="overline-title">Menu</h6>
                          </li>
                          <li className={"nk-menu-item " + ((this.state.user.role=="invig")?"active":"noactive")}>
                            <a href="/newdashboard" className="nk-menu-link">
                              <span className="nk-menu-icon"><em className="icon ni ni-users" /></span>
                              <span className="nk-menu-text">Student DashBoard</span>
                            </a>
                            <ul className="nk-menu-sub">
                              <li className="nk-menu-item active"><a href="#" className="nk-menu-link"><span className="nk-menu-text">Invite Student</span></a></li>
			    </ul>
                          </li>
                          <li className="nk-menu-item">
                            <a href="/newexamdash" className="nk-menu-link">
                              <span className="nk-menu-icon"><em className="icon ni ni-file-docs" /></span>
                              <span className="nk-menu-text">Exam Dashboard</span>
                            </a>
                            <ul className="nk-menu-sub">
                              <li className="nk-menu-item"><a href="\newexamdash#ongoing" className="nk-menu-link"><span className="nk-menu-text">Ongoing Exams</span></a></li>
                              <li className="nk-menu-item"><a href="\newexamdash#upcoming" className="nk-menu-link"><span className="nk-menu-text">Upcoming Exams</span></a></li>
                              <li className="nk-menu-item"><a href="\newexamdash#unscheduled" className="nk-menu-link"><span className="nk-menu-text">Unscheduled Exams</span></a></li>
                              <li className="nk-menu-item"><a href="\newexamdash#other" className="nk-menu-link"><span className="nk-menu-text">Past Exams </span></a></li>
                            </ul>
                          </li>
                          <li className="nk-menu-item">
                            <a href="\newlogin" className="nk-menu-link">
                              <span className="nk-menu-icon"><em className="icon ni ni-signout" /></span>
                              <span onClick={()=>sessionStorage.clear()} className="nk-menu-text">Log Out</span>
                            </a>
                          </li>
                        </ul>
                      </div>{/* .nk-sidebar-menu */}
                      <div className="nk-aside-close">
                        <a href="#" className="toggle" data-target="sideNav"><em className="icon ni ni-cross" /></a>
                      </div>{/* .nk-aside-close */}
                    </div>{/* .nk-aside */}
	<div className="nk-split-content nk-block-area nk-block-area-column nk-auth-container w-lg-45 bg-lighter">
	  <div className="nk-block mt-100 nk-auth-body">
		<div className="nk-block-head">
		  <div className="nk-block-head-content">
			<h5 className="nk-block-title">Invite Student</h5>
			<div className="nk-block-des">
			  <p>Proctor your online exams with <strong><em>Monitor Exam.</em>
				</strong></p></div><strong>
			</strong></div><strong>
		  </strong></div>{/* .nk-block-head */}
		  <div className="nk-block login-process">
			<div className="row">
			  <div className="col-lg-2">
				<span className="pro-num count-step1 active-num">1</span>
			  </div>
			  <div className="col-lg-10">
				<span className="num-heading">Step1</span>
				<p>Invite your students by entering their details</p>
			  </div>
			</div>
			<div className="row">
			  <div className="col-lg-2">
				<span className="pro-num count-step2 active-num">2</span>
			  </div>
			  <div className="col-lg-10">
				<span className="num-heading">Step 2</span>
				<p>Once the student joins from the invite you will be able to assign tests</p>
			  </div>
			</div>
		  </div>                    
		</div>{/* .nk-block */}
		{/* nk-block */}
	  </div>{/* nk-split-content */}
	  <div className="nk-split-content nk-split-stretch bg-lighter d-flex loginbox" data-content="athPromo" data-toggle-screen="lg" data-toggle-overlay="true">
		<div className="reg-form w-100 w-max-550px mt-100 loginform">
		  <form onSubmit={this.handleSubmit} >
		  <span id="errorMsg" className="badge badge-dim badge-outline-info "></span>
			<div id="step1" className="row step1">
			<div className="col-sm-6 form-group">
				<span className="form-icon"><em className="icon ni ni-user" /></span>
				<input type="text" name="first_name" onChange={this.handleChange} className="form-control form-control-lg" id="first_name" placeholder="First Name*" required />
			  </div>
			  <div className="col-sm-6 form-group">
				<span className="form-icon"><em className="icon ni ni-user" /></span>
				<input type="text" name="last_name" onChange={this.handleChange} className="form-control form-control-lg" id="last_name" placeholder="Last Name*" required />
			  </div>
			  <div className="col-sm-12 form-group">
				<span className="form-icon"><em className="icon ni ni-mail" /></span>
				<input type="text" name="email" onChange={this.handleChange} className="form-control form-control-lg" id="email" placeholder="Email*" required />
			  </div>
			  <div className="col-sm-12 form-group">
				<span className="form-icon"><em className="icon ni ni-mail" /></span>
				<input type="text" name="mobile" onChange={this.handleChange} className="form-control form-control-lg" id="mobile" placeholder="Mobile*" required />
			  </div>
			  <div className="col-sm-6 form-group">
				<button className="btn btn-lg btn-outline-primary btn-block" onClick={()=>window.location.reload()}>Clear Invite</button>
			  </div>
			  <div className="col-sm-6 form-group">
				<button className="btn btn-lg btn-primary btn-block" type="submit" >Invite Student</button>
			  </div>
			</div>
		  </form>{/* form */}
		</div>
	  </div>{/* nk-split-content */}
	</div>{/* nk-split */}
  </div>{/* app body @e */}
  {/* JavaScript */}
</div>
	</div>	
	       </div>
	</div>	
);

}
}
export default NewInvite
