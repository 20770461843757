import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import Http from 'axios'
import {
    Redirect
} from 'react-router-dom'

class Invite extends React.Component{
	constructor(){
		super();
	}
	handleChange = (event)=>{
		let nam=event.target.name;
		let val=event.target.value;
		this.setState({[nam]:val});
	}
	/*async componentWillMount() {
		if(this.props.user)
		{
	           sessionStorage.setItem('user',JSON.stringify(this.props.user));
		   console.log(this.props.user);
	           this.state.user=this.props.user;
		}
		else 
			this.state.user=JSON.parse(sessionStorage.getItem('user'));
	}*/
	handleSubmit = (event)=>{
                const errorMsgElement = document.querySelector('span#errorMsg');
		this.setState({user:JSON.parse(sessionStorage.getItem('user'))});
		const x=new Date();
    Http.defaults.headers.common['Authorization'] = this.state.user.token;
		event.preventDefault();
		        Http.post('user-api/useri',{
		          "first_name":this.state.first_name,
		          "last_name":this.state.last_name,
		          "email":this.state.email,
		          "mobile":this.state.mobile,
			  "invig_id":Number(this.state.user.id)
		        }, {
		          headers: {
		            "Content-Type": "application/json"
		          }
		        })
		      .then(res => {
                      const response = res.data;
                      //this.setState({ user:response,status:false});
		             //sessionStorage.setItem('user',JSON.stringify(response)); 
		            errorMsgElement.innerHTML=this.state.first_name+" invited successfully";
		            errorMsgElement.innerHTML=response;
		            console.log(this.state.first_name+" invited successfully");
	                return <Redirect to="/profile" />
                       })
                .catch(function(error) {
                    errorMsgElement.innerHTML="Invitation to "+this.state.first_name+" failed. Try sending again";
                    errorMsgElement.innerHTML = error;
                    console.log(error);
                });
	}
render() {

return(
<div>
                <nav className="navbar">
                  <nav className="navbar-brand">
           	        	<h3><a style={{color:"#fafafa"}} href="/"><img alt="monitorexam" src="/monitorexam_t.png" width="213px" height="120px"></img></a></h3>
                  </nav>
                  <nav className="navbar appmenu right">
                    <a className="nav-link" style={{color:"#6576ff"}} href="/examdash"> Exam Dashboard</a>
                    <a className="nav-link" style={{color:"#6576ff"}} href="/dashboard"> Student Dashboard</a>
                    <a className="nav-link" href="/login" style={{backgroundColor:"#6576ff",color:"white",borderRadius:"30px"}} onClick={()=>sessionStorage.clear()}>Logout</a>
                  </nav>
                </nav>
	        <br />
	        <br />
	        <br />
	        <br />
		<div className="row invite" style={{backgroundColor:"#f8faf7"}}>
		<div className="column side">
		</div>
		<div className="column middle" id="signup">
		<div className="flex-container" style={{flexDirection:"row" }}>
		<Card style={{paddingTop:"20px"}}>
		<Card.Body>
	        <img src="/invite.png" width="80%" height="80%" style={{paddingTop:'20px'}}></img>
		<p style={{color:"#b30000",align:"center"}}><span id="errorMsg"></span></p>
		<section className="form" >
		<form onSubmit={this.handleSubmit} >
		<div className="flex-container" style={{flexDirection:"column" }}>
		<div className="flex-container" style={{flexDirection:"row" }}>
		    <input type='text' placeholder='John' name='first_name' autoFocus onChange={this.handleChange} className="" required style={{margin:'2px',width:"45%"}}></input>
		    <input type='text' placeholder='Doe' name='last_name' onChange={this.handleChange} className="" required style={{margin:'2px',width:"45%"}}></input>
		</div>
		<div className="flex-container" style={{flexDirection:"column" }}>
		    <input type='text' placeholder='abc@def.com' name='email' onChange={this.handleChange}  className="flex-item" required style={{margin:'4px',width:'90%'}}></input>
		    <input type='text' placeholder='9999999999' name='mobile' onChange={this.handleChange} required  className="flex-item" style={{margin:'4px',width:'90%'}}></input>
		</div>
	        <Button style={{backgroundColor:"#6576ff",paddingTop:"10px", alignSelf:"left",border:"none",width:"90%"}} variant="primary" type='submit' placeholder='Invite' size="lg" required>Invite Student</Button>
	        </div>
	        </form>
	        </section>
		</Card.Body>
		</Card>
	</div>
	        </div>
	        </div>
</div>
);
}
}
export default Invite
