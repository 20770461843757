import React,{Component} from 'react'
import ReactDOM from 'react-dom'
import Http from 'axios'
import NewLogin from './NewLogin'

class ViewProfile extends Component{

    constructor(){
		super();
		this.state={
            isLoaded:false, 
            isLogged:false, 
            users:[],
            userMenu:false,
            menu:false
        };
    this.toggleMenu=this.toggleMenu.bind(this);
    this.toggleUserMenu=this.toggleUserMenu.bind(this);
}
componentWillMount() {
  // Get user inputs  
	//    const { userId } = this.props.match.params;
	//	    var api='user-api/user/'+{userId};
	//	    const response = await Http.get(api);
          //          this.setState({ isLoaded:true, users:response.data });
  var user = JSON.parse(sessionStorage.getItem('user'));
    if (this.props.user) {
        this.state.user = this.props.user;
        this.state.isLoaded = true;
        this.state.isLogged = true;
    }
    else if (user) {
        this.state.user = user;
        this.state.isLoaded = true;
        this.state.isLogged = true;
    }
        else {
        console.log("User not available");
        this.state.isLoaded = false;
      }
}
toggleUserMenu() {
        this.setState({userMenu:!this.state.userMenu});  
}
toggleMenu() {
        this.setState({menu:!this.state.menu});  
}
displayTimezone(){
  var timezone_offset_min = new Date().getTimezoneOffset(),
	offset_hrs = parseInt(Math.abs(this.state.user.timezone/60)),
	offset_min = Math.abs(timezone_offset_min%60),
	timezone_standard;

if(offset_hrs < 10)
	offset_hrs = '0' + offset_hrs;

if(offset_min < 10)
	offset_min = '0' + offset_min;

// Add an opposite sign to the offset
// If offset is 0, it means timezone is UTC
if(timezone_offset_min < 0)
	timezone_standard = '+' + offset_hrs + ':' + offset_min;
else if(timezone_offset_min > 0)
	timezone_standard = '-' + offset_hrs + ':' + offset_min;
else if(timezone_offset_min == 0)
	timezone_standard = 'Z';

// Timezone difference in hours and minutes
// String such as +5:30 or -6:00 or Z
console.log(timezone_standard); 
return ("UTC"+timezone_standard);
}
    render(){
    //const { userId } = this.props.match.params;
      if(this.state.isLoaded) {
        const show = this.state.menu ? "active" : "";
        const showMenu = this.state.menu ? "content-active" : "";
        const usershow = this.state.userMenu ? "show" : "";  
      return (
        <div>
          <meta charSet="utf-8" />
          <meta name="author" content="Softnio" />
          <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
          <meta name="description" content="@@page-discription" />
          {/* Fav Icon  */}
          <link rel="shortcut icon" href="images/favicon.png" />
          {/* Page Title  */}
          <title>View Profile | Monitor Exam</title>
          {/* StyleSheets  */}
          <link rel="stylesheet" href="assets/css/style.css" />
          <link rel="stylesheet" href="assets/css/custom.css" />
          <link id="skin-default" rel="stylesheet" href="assets/css/theme.css?ver=1.2.0" />
          <div className="nk-app-root">
            {/* main @s */}
            <div className="nk-main ">
              {/* wrap @s */}
              <div className="nk-wrap ">
                <div className="nk-header nk-header-fixed is-light">
                  <div className="container-lg wide-xl">
                    <div className="nk-header-wrap">
                      <div className="nk-header-brand">
                        <a href="#" className="logo-link">
                          <img className="logo-dark logo-img" src="images/monitor-exam-logo.png" srcSet="images/monitor-exam-logo-2x.png 2x" alt="logo-dark" />
                        </a>
                      </div>{/* .nk-header-brand */}
                      <div className="nk-header-tools">
                        <ul className="nk-quick-nav">
                        <li onClick={this.toggleUserMenu} className={"dropdown user-dropdown " + usershow}>
                            <a href="#" className="dropdown-toggle" data-toggle="dropdown" aria-expanded={this.state.userMenu}>
                              <div className="user-toggle">
                                <div className="user-avatar sm">
                                <img src={'https://d1bumj8k6ax755.cloudfront.net/uploads/' + this.state.user.reg_pic.split("'")[1]} class="regpic " alt="reg" style={{ transform: "scale(0.5)", left: "6px" }} />
                                  <em className="icon ni ni-user-alt" />
                                </div>
                                <div className="user-name dropdown-indicator d-none d-sm-block">{this.state.user.first_name} {this.state.user.last_name}</div>
                              </div>
                            </a>
                            <div className={"dropdown-menu dropdown-menu-md dropdown-menu-right dropdown-menu-s1 "+usershow}>
                              <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
                                <div className="user-card">
                                  <div className="user-avatar">
																	<span>{this.state.user.first_name.charAt(0).toUpperCase()+this.state.user.last_name.charAt(0).toUpperCase()}</span>
                                  </div>
                                  <div className="user-info">
                                    <span className="lead-text">{this.state.user.first_name} {this.state.user.last_name}</span>
                                    <span className="sub-text">{this.state.user.email}</span>
                                  </div>
                                  <div className="user-action">
                                    <a className="btn btn-icon mr-n2" href="#"><em className="icon ni ni-setting" /></a>
                                  </div>
                                </div>
                              </div>
                              <div className="dropdown-inner">
                                <ul className="link-list">
                                  <li><a href="\viewprofile"><em className="icon ni ni-user-alt" /><span>View Profile</span></a></li>
                                  <li><a href="/security"><em className="icon ni ni-setting-alt" /><span>Security Setting</span></a></li>
                                  <li><a href="#"><em className="icon ni ni-activity-alt" /><span>Login Activity</span></a></li>
                                </ul>
                              </div>
                              <div className="dropdown-inner">
                                <ul className="link-list">
                                  <li><a href="\newlogin"><em className="icon ni ni-signout" /><span onClick={()=>sessionStorage.clear()}>Sign Out</span></a></li>
                                </ul>
                              </div>
                            </div>
                          </li>{/* .dropdown */}
                          <li onClick={this.toggleMenu} className="d-lg-none">
                              <a href="#" className={"toggle nk-quick-nav-icon mr-n1 "+this.state.menu?"active":""} data-target="sideNav"><em className="icon ni ni-menu" /></a>
                          </li>
                        </ul>{/* .nk-quick-nav */}
                      </div>{/* .nk-header-tools */}
                    </div>{/* .nk-header-wrap */}
                  </div>{/* .container-fliud */}
                </div>
                {/* main header @e */}
                {/* content @s */}
                <div className="nk-content">
                  <div className="container-fluid">
                    <div className="nk-content-inner">
                      <div className={"nk-aside "+showMenu}  data-content="sideNav" data-toggle-overlay="true" data-toggle-screen="lg" data-toggle-body="true">
                        <div className="nk-sidebar-menu" data-simplebar>
                          {/* Menu */}
                          <ul className="nk-menu d-none">
                            <li className={"nk-menu-item " + ((this.state.user.role=="invig")?"active":"noactive")}>
                              <a href="/newdashboard" className="nk-menu-link">
                                <span className="nk-menu-icon"><em className="icon ni ni-users" /></span>
                                <span className="nk-menu-text">Student DashBoard</span>
                              </a>
                            <ul className="nk-menu-sub">
                              <li className="nk-menu-item "><a href="\livestudentdash" className="nk-menu-link"><span className="nk-menu-text">Live Students</span></a></li>
                              <li className="nk-menu-item "><a href="\newdashboard" className="nk-menu-link"><span className="nk-menu-text">All Students</span></a></li>
		            </ul>
                            </li>
                            <li className="nk-menu-item active">
                              <a href="/testdash" className="nk-menu-link">
                                <span className="nk-menu-icon"><em className="icon ni ni-file-docs" /></span>
                                <span className="nk-menu-text">Exam Dashboard</span>
                              </a>
                              <ul className="nk-menu-sub">
                                <li className="nk-menu-item"><a href="\testdash" className="nk-menu-link"><span className="nk-menu-text">Ongoing Exams</span></a></li>
                                <li className="nk-menu-item"><a href="\upcoming" className="nk-menu-link"><span className="nk-menu-text">Upcoming Exams</span></a></li>
                                <li className="nk-menu-item"><a href="\unscheduled" className="nk-menu-link"><span className="nk-menu-text">Unscheduled Exams</span></a></li>
                                <li className="nk-menu-item"><a href="\pastexamdash" className="nk-menu-link"><span className="nk-menu-text">Past Exams </span></a></li>
                              </ul>
                            </li>
                            <li className="nk-menu-item">
                              <a href="\newlogin" className="nk-menu-link">
                                <span className="nk-menu-icon"><em className="icon ni ni-signout" /></span>
                                <span onClick={()=>sessionStorage.clear()} className="nk-menu-text" >Log Out</span>
                              </a>
                            </li>
                          </ul>
                        </div>{/* .nk-sidebar-menu */}
                        <div className="nk-aside-close">
                          <a href="#" className="toggle" data-target="sideNav"><em className="icon ni ni-cross" /></a>
                        </div>{/* .nk-aside-close */}
                      </div>{/* .nk-aside */}
                      <div className="nk-content-body">
                        <div className="nk-block">
                          <div className="card card-bordered">
                            <div className="card-aside-wrap">
                              <div className="card-inner card-inner-lg">
                                <div className="nk-block-head nk-block-head-lg">
                                  <div className="nk-block-between">
                                    <div className="nk-block-head-content">
                                      <h4 className="nk-block-title">Personal Information</h4>
                                      <div className="nk-block-des"><p>Basic information </p></div>
                                    </div>
                                    <div className="nk-block-head-content align-self-start d-lg-none">
                                      <a href="#" className="toggle btn btn-icon btn-trigger mt-n1" data-target="userAside"><em className="icon ni ni-menu-alt-r" /></a>
                                    </div>
                                  </div>
                                </div>
                                <div className="nk-block">
                                <span id="errorMsg" className="badge badge-dim badge-outline-info "></span>
                                  <div className="nk-data data-list">
                                    <div className="data-head"><h6 className="overline-title">Basics</h6></div>
                                    <div className="data-item" data-toggle="modal" data-target="#profile-edit">
                                      <div className="data-col"><span className="data-label">Full Name</span><span className="data-value">{this.state.user.first_name} {this.state.user.last_name}</span></div>
                                      <div className="data-col data-col-end">
                                        <span className="data-more"><em className="icon ni ni-forward-ios" /></span>
                                      </div>
                                    </div>
                                    <div className="data-item" data-toggle="modal" data-target="#profile-edit">
                                      <div className="data-col"><span className="data-label">Display Name</span><span className="data-value">{this.state.user.first_name}</span></div>
                                      <div className="data-col data-col-end">
                                        <span className="data-more"><em className="icon ni ni-forward-ios" /></span>
                                      </div>
                                    </div>
                                    <div className="data-item" data-toggle="modal" data-target="#profile-edit">
                                      <div className="data-col"><span className="data-label">Display ID</span><span className="data-value">{this.state.user.id}</span></div>
                                      <div className="data-col data-col-end">
                                        <span className="data-more"><em className="icon ni ni-forward-ios" /></span>
                                      </div>
                                    </div>
                                    <div className="data-item" data-toggle="modal" data-target="#profile-edit">
                                      <div className="data-col"><span className="data-label">Last Login</span><span className="data-value">{this.state.user.last_login}</span></div>
                                      <div className="data-col data-col-end">
                                        <span className="data-more"><em className="icon ni ni-forward-ios" /></span>
                                      </div>
                                    </div>
                                    <div className="data-item">
                                      <div className="data-col"><span className="data-label">Email</span><span className="data-value">{this.state.user.email}</span></div>
                                      <div className="data-col data-col-end">
                                        <span className="data-more disable"><em className="icon ni ni-lock-alt" /></span>
                                      </div>
                                    </div>
                                    <div className="data-item" data-toggle="modal" data-target="#profile-edit">
                                      <div className="data-col"><span className="data-label">Phone Number</span><span className="data-value text-soft">{this.state.user.mobile}</span></div>
                                      <div className="data-col data-col-end">
                                        <span className="data-more"><em className="icon ni ni-forward-ios" /></span>
                                      </div>
                                    </div>
                                    <div className="data-item" data-toggle="modal" data-target="#profile-edit">
                                      <div className="data-col"><span className="data-label">Class</span><span className="data-value">{this.state.user.class}</span></div>
                                      <div className="data-col data-col-end">
                                        <span className="data-more"><em className="icon ni ni-forward-ios" /></span>
                                      </div>
                                    </div>
                                    <div className="data-item" data-toggle="modal" data-target="#profile-edit" data-tab-target="#address">
                                      <div className="data-col">
                                        <span className="data-label">School</span>
                                        <span className="data-value">
                                        {this.state.user.school}<br />
                                          
                                        </span>
                                      </div>
                                      <div className="data-col data-col-end">
                                        <span className="data-more"><em className="icon ni ni-forward-ios" /></span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="nk-data data-list">
                                    <div className="data-head"><h6 className="overline-title">Preferences</h6></div>
                                    <div className="data-item">
                                      <div className="data-col"><span className="data-label">Language</span><span className="data-value">English</span></div>
                                      <div className="data-col data-col-end noactive"><a href="#" data-toggle="modal" data-target="#profile-language" className="link link-primary">Change Language</a></div>
                                    </div>
                                    <div className="data-item">
                                      <div className="data-col"><span className="data-label">Date Format</span><span className="data-value">dd/mm/YYYY</span></div>
                                      <div className="data-col data-col-end noactive"><a href="#" data-toggle="modal" data-target="#profile-language" className="link link-primary">Change</a></div>
                                    </div>
                                    <div className="data-item">
                                      <div className="data-col"><span className="data-label">Timezone</span><span className="data-value">{this.displayTimezone()}</span></div>
                                      <div className="data-col data-col-end noactive"><a href="#" data-toggle="modal" data-target="#profile-language" className="link link-primary">Change</a></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>                
                {/* content @e */}
              </div>
              {/* wrap @e */}
            </div>
            {/* main @e */}
          </div>
          {/*footer*/}

          {/* app-root @e */}
          {/* JavaScript */}
        </div>
      );
      }
      else {
        <NewLogin />
      }
    }
  }

  export default ViewProfile
