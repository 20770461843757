import React,{Component} from 'react'
import ReactDOM from 'react-dom'
import Button from 'react-bootstrap/Button'
import Http from 'axios'
import '../Auth/Auth.css'
import Card from 'react-bootstrap/Card'

class AdminExamDashboard extends Component{
	constructor(){
		super();
		this.state={isLoaded:false, exams:[],id:0};
	}
	gotoProfile=(id,exam)=>{
		//sessionStorage.setItem('exam',JSON.stringify(this.state.exams[id-1]));
	}
	handleChange = (event)=>{
		let nam=event.target.name;
		let val=event.target.value;
		this.setState({[nam]:val});
		this.setState({id:0});
	}
	handleClick=(exam)=>{
		sessionStorage.setItem('exam1',JSON.stringify(exam));
		const status=(Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Ongoing":"Done"):"Not Started";
		if(status=="Not Started"){
			console.log("Adding student to exam");
		if(this.state.user_id) {
		    var api="exam-api/exame/" + this.state.user_id + "/" + exam.id ;
	        Http.post(api)
	        .then(res => {
                     console.log(res);
               })
               .catch(function (error) {
                     console.log(error);
               });
			}
		}
		else if(status=='Ongoing')
		{
			console.log("Too late to start exams");
		}
		else
		{
			console.log("Exam has already occured.");
		}
	}
	async componentWillMount() {
		const user = JSON.parse(sessionStorage.getItem('user'));
		this.state.user=user;
		if(!this.state.user)
			this.state.user=this.props.user;
	    if(this.state.user.role=='admin') {
		    var api="exam-api/exam";
		    if(this.state.user)
		       sessionStorage.setItem('user',JSON.stringify(this.state.user));
	    }
	    else {
		    var api="exam-api/examu/" + this.state.user.id ;
		    //var api="exam-api/examu/" + this.props.match.params.user.id ;
		    if(this.state.user)
		       sessionStorage.setItem('user',JSON.stringify(this.state.user));
	    }
	     const response = await	Http.get(api);
             this.setState({ isLoaded:true, exams:response.data });
	}
	async componentDidMount(){
	    /*var api="exam-api/examm/"+16 + "/" + 1;
	     const response = await	Http.post(api,{
                          "user_id":16,
                          "exam_id":1,
                        }, {
                          headers: {
                            "Content-Type": "application/json"
                          }
                        })
                      .then(res => {
                      const response = res;
			      console.log(response);
                       })
*/
	}
	componentWillUnmount(){
		console.log(sessionStorage.getItem('exam1'));
		console.log(sessionStorage.getItem('user'));
	}
	toLocal = (utctime) => { var x=new Date(Date.parse("1970-01-01T"+utctime.toString()+"Z"));console.log(x);x+=this.state.user.timezone*60000;return(x.toLocaleString().slice(16,24));}
	render() {
	return(
		<div>
		<h4 style={{color:"white",backgroundColor:"#00b3b3", padding:"8px"}}>{this.state.user.first_name}'s Exam Dashboard</h4>
<nav className="navbar" style={{color:"#00b3b3",padding:"8px"}}>
  <nav className="navbar-brand">
	        	<h3><a style={{color:"#fafafa"}} href="/"><img src="/monitorexam_t.png" width="213px" height="120px"></img></a></h3>
  </nav>
  <nav className="navbar appmenu right" style={{padding:"8px"}}>
    <a className="nav-link" style={{display:this.state.user.role=='admin'?'block':'none',color:"#00b3b3"}} href="/admindashboard">User Dashboard</a>
    <a className="nav-link" style={{display:this.state.user.role=='admin'?'block':'none',color:"#00b3b3"}} href="/form#createexam">Create Exam</a>
    <a className="nav-link" href="/login" style={{color:"#00b3b3",borderRadius:"30px"}} onClick={()=>sessionStorage.clear()}>Logout</a>
  </nav>
</nav>
		<br />
		<br />
		<br />
		<br />
		<div className="row" style={{backgroundColor:"#f8faf7"}}>
		<div style={{backgroundColor:"white", color:"#00b3b3",margin:"16px"}} className="column side">
		<h5>Side Menu</h5>
		<ul>
		<li><a style={{color:""}} href="/testdash">Ongoing</a></li>
		<li><a style={{color:""}} href="/upcoming">Upcoming</a></li>
		<li><a style={{color:""}} href="/unscheduled">Upcoming</a></li>
		<li><a style={{color:""}} href="/pastexamdash">Past</a></li>
		</ul>
		</div>
		<div className="column middle">
                <Button style={{backgroundColor:"#00b3b3",justifyContent:"right",display:this.state.user.role=='admin'?'block':'none'}} className="float-right"><a className="float-right" style={{boarderRadius:"6px",textAlign:"right",display:this.state.user.role=='admin'?'block':'none',backgroundColor:"#00b3b3",color:"white"}} href="/form">Create Exam</a></Button>
		<table style={{borderSpacing:"5px",width:"100%"}} className='css-serial table-responsive'>
		<div id="ongoing">
		<Card>
		<Card.Title>Ongoing Exams</Card.Title>
		<Card.Body>

		<tr style={{paddingLeft:"15px", textAlign:"centre"}}>
		  <th> #</th>
		  <th style={{padding:"15px"}}> Name </th>
		  <th style={{padding:"15px"}}> Date</th>
		  <th style={{padding:"15px"}}> Time</th>
		  <th>Camera </th>
		  <th>Network </th>
		  <th>Battery </th>
		  <th>Status</th></tr>
		<>
		{this.state.exams.filter(exam=>(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now()))&&((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now())))).map(exam=>( 
			<tr style={{padding:"15px",textAlign:"centre"}}>
			  <td id="exam_id">{++this.state.id}</td>
  			  <td> {exam.name}</td>
			  <td>{Intl.DateTimeFormat().format(Date.parse(exam.date) + Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))}</td>
			  <td>{this.toLocal(exam.start_time)}</td>
			  <td>{exam.camera_stateR.toString()}</td>
			  <td>{exam.network_stateR.toString()}</td>
			  <td>{exam.battery_stateR.toString()}</td>
			  <td>{((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now()))?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Ongoing":"Past"):"Upcoming"}</td>
			<td>
			<input name="note" type="text" style={{display:this.state.user.role=='admin'?"block":"none"}} placeholder="Observation about student" onChange={this.handleChange} />
			</td>
			<td style={{display:"table-cell",backgroundColor:"#00b3b3",color:"white",borderRadius:'6px',padding:'10px'}}>
			<a href='/monidash' style={{color:"white",display:this.state.user.role=='admin'?"block":"none"}} onClick={()=>this.handleClick(exam)}>
			{((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now()))?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Monitor":"View"):"Add Student"}
			</a>
			<a href='/profile' style={{color:"white",display:this.state.user.role=='admin'?"none":"block"}} onClick={()=>sessionStorage.setItem('exam1',JSON.stringify(exam))}>
			{((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now()))?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Start Exam":"Done"):"Not Started"}
			</a>
			</td>
			</tr>))}
		</>
		</Card.Body>
		</Card>
		</div>
		<div id="upcoming">
		<Card>
		<Card.Title>Upcoming Exams</Card.Title>
		<Card.Body>

		<tr style={{paddingLeft:"15px", textAlign:"centre"}}>
		  <th> #</th>
		  <th style={{padding:"15px"}}> Name </th>
		  <th style={{padding:"15px"}}> Date</th>
		  <th style={{padding:"15px"}}> Time</th>
		  <th>Camera </th>
		  <th>Network </th>
		  <th>Battery </th>
		  <th>Status</th></tr>
		<>
		{this.state.exams.filter(exam=>((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))>(Date.now()))).map(exam=>( 
			<tr style={{padding:"15px",textAlign:"centre"}}>
			  <td id="exam_id">{++this.state.id}</td>
  			  <td> {exam.name}</td>
			  <td>{Intl.DateTimeFormat().format(Date.parse(exam.date) + Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))}</td>
			<td>{this.toLocal(exam.start_time)}</td>
			  <td>{exam.camera_stateR.toString()}</td>
			  <td>{exam.network_stateR.toString()}</td>
			  <td>{exam.battery_stateR.toString()}</td>
			  <td>{(Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Ongoing":"Past"):"Upcoming"}</td>
			<td>
			<input name="user_id" type="text" style={{display:this.state.user.role=='admin'?"block":"none"}} placeholder="Student Id" onChange={this.handleChange} />
			</td>
			<td style={{backgroundColor:"#00b3b3",color:"white",borderRadius:'6px',padding:'10px'}}>
			<a href='/monidash' style={{color:"white",display:this.state.user.role=='admin'?"block":"none"}} onClick={()=>this.handleClick(exam)}>
			{(Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Monitor":"View"):"Add Student"}
			</a>
			<a href='/profile' style={{color:"white",display:this.state.user.role=='admin'?"none":"block"}} onClick={()=>sessionStorage.setItem('exam1',JSON.stringify(exam))}>
			{(Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Start Exam":"Done"):"Not Started"}
			</a>
			</td>
			</tr>))}
		</>
		</Card.Body>
		</Card>
		</div>
		<div id="other">
		<Card>
		<Card.Title>Past Exams</Card.Title>
		<Card.Body>
		<tr style={{paddingLeft:"15px", textAlign:"centre"}}>
		  <th> #</th>
		  <th style={{padding:"15px"}}> Name </th>
		  <th style={{padding:"15px"}}> Date</th>
		  <th style={{padding:"15px"}}> Time</th>
		  <th>Camera </th>
		  <th>Network </th>
		  <th>Battery </th>
		  <th>Status</th></tr>
		<>
		{this.state.exams.filter(exam=>((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)<(Date.now()))).map(exam=>( 
			<tr style={{padding:"15px",textAlign:"centre"}}>
			  <td id="exam_id">{++this.state.id}</td>
  			  <td> {exam.name}</td>
			  <td>{Intl.DateTimeFormat().format(Date.parse(exam.date) + Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))}</td>
			<td>{this.toLocal(exam.start_time)}</td>
			  <td>{exam.camera_stateR.toString()}</td>
			  <td>{exam.network_stateR.toString()}</td>
			  <td>{exam.battery_stateR.toString()}</td>
			  <td>{((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now()))?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Ongoing":"Past"):"Upcoming"}</td>
			<td style={{backgroundColor:"#00b3b3",color:"white",borderRadius:'6px',padding:'10px'}}>
			<a href='/monidash' style={{color:'white',display:this.state.user.role=='admin'?"block":"none"}} onClick={()=>this.handleClick(exam)}>
			{((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now()))?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Monitor":"View"):"Add Student"}
			</a>
			<a href='/profile' style={{color:'white',padding:"4px",display:this.state.user.role=='admin'?"none":"block"}} onClick={()=>sessionStorage.setItem('exam1',JSON.stringify(exam))}>
			{(Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z"))<(Date.now())?(((Date.parse(exam.date)+Date.parse("1970-01-01T"+exam.start_time.toString()+"Z")+exam.duration*60000)>(Date.now()))?"Start Exam":"Done"):"Not Started"}
			</a>
			</td>
			</tr>))}
		</>
		</Card.Body>
		</Card>
		</div>
		</table>
		</div>
		</div>
		</div>
	);
	}
}


export default AdminExamDashboard
