import React,{Component} from 'react'
import Button from 'react-bootstrap/Button'
import Http from 'axios'
import './Chat.css';
import Pubnub from './Pubnub'

class Chat extends Component {
	constructor(){
		super();
		this.state={msg:'start',sentiment:'unknown',uuid:"temp"};
    const user_id=JSON.parse(sessionStorage.getItem('user')).id;
    this.state.uuid=user_id+"monitorexam_com";
    console.log(this.state.uuid);
	}
	handleChange = (event)=>{
		let nam=event.target.name;
		let val=event.target.value;
		this.setState({[nam]:val});
	}

	sendChat = (event) => {
		event.preventDefault();
		var config = {
                  method: 'get',
                  url: 'https://api.wit.ai/message?v=20200827&q='+this.state.msg,
                  headers: {
                    'Authorization': 'Bearer DDWMOBTNMSYZ7B7JHDLQ233BMLC7WXRG'
                  }
                };

                Http(config)
                .then(function (response) {
                  const sentiment=response.data.traits.wit$sentiment[0].value;
                  const chatMsgElement = document.querySelector('span#sentiment');
                  chatMsgElement.innerHTML = `Examinee is feeling ` + sentiment;
                })
                .catch(function (error) {
                  console.log(error);
                });
                console.log('Submitted' + this.state.msg);
		const messagesTop = document.getElementById('messages-top');
		const updateText = document.getElementById('update-text');
		const sendButton = document.getElementById('publish-button');
		//sendButton.addEventListener('click', () => {submitUpdate(theEntry, updateText.value)});

	}

  render(){
	  return(
		<div className="chat-popup" id="myForm" style={{float:"right"}}>
		<Pubnub uuid={this.state.uuid}/>
    <form className="form-container" style={{display:"none"}}>
    <h5>Chat</h5>
      <label for="msg"><b>Message {new Date().toLocaleTimeString()}</b></label>
		<br></br>
      <textarea placeholder="Click here to Chat .." name="msg"  id="messages-top" onChange={this.handleChange} required></textarea>
		<br></br>
		  <p><span id='sentiment'></span></p>
      <Button type="submit" className="btn" id="publish-button" style={{backgroundColor:"#00b3b3",border:0}} onClick={this.sendChat}>Send</Button>
    </form>
    </div>
	);
	}
}

export default Chat
