import React, {
    Component
} from 'react'
import ReactDOM from 'react-dom'
import Button from 'react-bootstrap/Button'
import Http from 'axios'
import {
    Map,
    GoogleApiWrapper,
    Marker
} from 'google-maps-react'
import Profile from './Profile'
import Test from './Test'
import {
    Redirect
} from 'react-router-dom'

const mapStyles = {
    width: '40%',
    height: '40%'
};


//Please note that verifyStudent is only Step 3
//This is what user sees when he enters the exam. His environment gets verified here
//change log: 1 May Updated the image click also in the verification function
class VerifyStudent extends React.Component {
        constructor() {
            super();
            this.state = {
                status: true,
                testStarted: false,
      
            };
	    this.handleCapture=this.handleCapture.bind(this);
	    this.updateState=this.updateState.bind(this);
        }
        verifyCamera = (event) => {
            event.preventDefault();
        }
        showPosition = (position) => {
            this.state.lat = position.coords.latitude;
            this.state.lng = position.coords.longitude;
        }
        
        componenWillMount()  {
            this.state = {
                status: true,
                testStarted: false,
                camera_state: this.props.user.camera_state,
                network_state: this.props.user.network_state,
                battery_state: this.props.user.battery_state,
                battery_level: this.props.user.battery_level,
                cred: this.props.user.cred,
                ip: this.props.user.ip,
                captured: this.props.user.captured,
                live_pic:this.props.user.live_pic,
                reg_pic:this.props.user.reg_pic
            };

        
        }
	    //called every 30 seconds updates status only
        updateState = (event) => {
		//cred is a credibility score
            var status,cred =0;
            Http.defaults.headers.common['Authorization'] = this.props.user.token;
            console.log(document.hidden || document.msHidden || document.webkitHidden);
            console.log(document.webkitVisibilityState);
            this.state.user=this.props.user;
		    if(this.state.cred==0) {
		    if(this.props.user.cred!==undefined)
			    cred = this.props.user.cred;
			    console.log("Cred not yet defined");
		    }
		    else
		        cred = this.state.cred;
		        

		    
		    
            if (document.hidden || document.webkitVisibilityState != "visible" && this.state.status=="Present") {
                status = "Distracted";
		        cred=cred-1;
            }
	        else if(this.state.status=="Away")
	        {
		        status = "Away";
		        cred=cred-1;
	        } 
	        else {
		        status = "Present";
		        cred=cred+4;
	        }
	        
	      //  this.environmentCheck();
	      
	        //Initialize test variables for user    
	      	cred=0;
	      	status="Verifying";
	        this.state.user.status=status;
	        this.state.user.cred=cred;
	        this.setState({cred:cred});
            const last_login = new Date().toISOString().slice(0, 19).replace('T', ' ');
            this.setState({last_login:last_login});
            this.state.user.last_login=last_login;
            this.state.user.network_state=this.state.network_state;
            this.state.user.battery_state=this.state.battery_state;
            this.state.user.battery_level=this.state.battery_level;
            this.state.user.camera_state=this.state.camera_state;
            this.state.user.ip=this.state.ip;
            this.state.user.live_pic=this.state.live_pic;
            sessionStorage.setItem('user', JSON.stringify(this.state.user));
            
            
		    //separete handling for user having a registered pic and not
		    console.log(this.props.user.live_pic);
		    if(this.props.user.live_pic||this.state.live_pic) {
            Http.put('user-api/user/' + this.props.user.id, {
                    "camera_state": this.props.user.camera_state,
                    "network_state": this.state.network_state,
                    "battery_state": this.state.battery_state,
                    "battery_level": this.state.battery_level.toString(),
                    "status": status,
                    "ip": this.state.ip,
                    "lat": this.state.lat,
                    "lng": this.state.lng,
                    "last_login": last_login,
		            "live_pic":this.props.user.live_pic,
		            "cred":cred
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(res => {
                    console.log("User updated Successfully");
                });
		}/* else {
            Http.put('user-api/user/' + this.props.user.id, {
                    "camera_state": this.state.camera_state,
                    "network_state": this.state.network_state,
                    "battery_state": this.state.battery_state,
                    "battery_level": this.state.battery_level.toString(),
                    "status": status,
                    "ip": this.state.ip,
                    "lat": this.state.lat,
                    "lng": this.state.lng,
                    "last_login": last_login,
                }, {
                    headers: {
                        "Content-Type": "application/json"
                    }
                })
                .then(res => {
                    //console.log("User updated Successfully");
                })
		}*/
        }
        async environmentCheck() {
            const video = document.getElementById('video');
            const errorMsgElement = document.querySelector('span#errorMsg');
            const cameraMsgElement = document.querySelector('span#cameraMsg');
            const nwMsgElement = document.querySelector('span#nwMsg');
            const baMsgElement = document.querySelector('span#baMsg');
            const ipMsgElement = document.querySelector('span#ipMsg');
		//Location
            navigator.geolocation.getCurrentPosition(this.showPosition);
		//IP
            Http.get('https://api.ipify.org?format=json')
                .then(res => {
                    const ipnew = res.data.ip;
                    console.log(ipnew);
                    this.setState({
                        ip: ipnew
                    });
                    ipMsgElement.innerHTML = ` <img alt="ip" src="/i/ip.png" width="15%"></img> ......`+" "+this.state.ip;
                });
            this.setState({
                network_state: navigator.onLine
            });
		//Network
            if (navigator.onLine)
                nwMsgElement.innerHTML = ` <img alt="network" src="/i/network.png" width="15%"></img> ...... &#10003 Network`;
            else
                nwMsgElement.innerHTML = ` <img alt="network" src="/i/network.png" width="15%"></img> ...... &#10005`;
            var connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
	    connection.onChange=this.updateState;
            if (connection) {
                var speed = `None`;
                switch (connection.effectiveType) {
                    case "4g":
                        speed = "good";
                        break;
                    case "3g":
                        speed = "fair";
                        break;
                    case "2g":
                        speed = "slow";
                        break;
                    case "slow-2g":
                        speed = "slow";
                        break;
                }
                nwMsgElement.innerHTML = `Network speed is ` + connection.downlink + "Mbps";
            }
            //var battery=navigator.battery||navigator.mozBattery||navigator.webkitBattery;
		//Battery
            if (navigator.getBattery) {
                 var level=navigator.getBattery().then(function(battery) {
                    baMsgElement.innerHTML = `<img alt="battery" src="/i/battery.png" width="15%"> ...... &#10003  level ` + battery.level * 100 + '% ' + (battery.charging ? 'Charging' : 'Not Charging')
                    baMsgElement.innerHTML += `<progress value=` + battery.level * 100 + ` max="100" style={{color:"#6576ff"}} width=250px height=25px>%</progress>`;
		            return battery.level*100;
                }).then((result)=>this.setState({ battery_state: true,battery_level:result }));
            } else {
                this.setState({
                    battery_state: false
                });
                baMsgElement.innerHTML = `<img alt="battery" src="/i/battery.png" width="15%"> ...... &#10005`
            }

	    //Bluetooth
           /*
           navigator.bluetooth.requestDevice({
                    acceptAllDevices: true
                })
                .then(function(device) {
                    console.log(device.name);
                });
            */
            const constraints = {
                audio: false,
                video: {
                    width: 320,
                    height: 240
                }
            };

	    //Camera
            try {
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                window.stream = stream;
                video.srcObject = stream;
                this.setState({
                    camera_state: true
                });
                cameraMsgElement.innerHTML = `<img alt="camera" src="/i/camera.png" width="15%"></img> ...... &#10003`;
            } catch (e) {
                this.setState({
                    camera_state: false
                });
                cameraMsgElement.innerHTML = `<img alt="camera" src="/i/camera.png" width="15%"></img> ...... &#10005`;
                errorMsgElement.innerHTML = `navigator.getUserMedia error:${e.toString()}`;
            }
	    //TODO: Desktop
		//Image Capture
		//Voice Capture .slice(0, 19).replace('T', ' ')
        }
        
        //Will be called post rendering
        async componentDidMount() {
            const video = document.getElementById('video');
            const errorMsgElement = document.querySelector('span#errorMsg');
            const cameraMsgElement = document.querySelector('span#cameraMsg');
            const nwMsgElement = document.querySelector('span#nwMsg');
            const ipMsgElement = document.querySelector('span#ipMsg');
            const baMsgElement = document.querySelector('span#baMsg');
            
            //data received from profile component
            this.setState( {
                status: true,
                testStarted: false,
                camera_state: this.props.user.camera_state,
                network_state: this.props.user.network_state,
                battery_state: this.props.user.battery_state,
                battery_level: this.props.user.battery_level,
                cred: this.props.user.cred,
                ip: this.props.user.ip,
                captured: this.props.user.captured,
                live_pic:this.props.user.live_pic,
                reg_pic:this.props.user.reg_pic
            });
            
            
            //event connection change
            var connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection;
	        connection.onChange=this.updateState;
            
            
            navigator.geolocation.getCurrentPosition(this.showPosition);
            
            this.setState({
                network_state: navigator.onLine
            });

            
            if (navigator.onLine)
                nwMsgElement.innerHTML = ` <img alt="network" src="/i/network.png" width="15%"></img> ...... &#10003 Network`;
            else
                nwMsgElement.innerHTML = ` <img alt="network" src="/i/network.png" width="15%"></img> ...... &#10005`;

            if (connection) {
                var speed = `None`;
                switch (connection.effectiveType) {
                    case "4g":
                        speed = "good";
                        break;
                    case "3g":
                        speed = "fair";
                        break;
                    case "2g":
                        speed = "slow";
                        break;
                    case "slow-2g":
                        speed = "slow";
                        break;
                }
                nwMsgElement.innerHTML = `Network speed is ` + connection.downlink + "Mbps";
            }
            
            //var battery=navigator.battery||navigator.mozBattery||navigator.webkitBattery;
            if (navigator.getBattery) {
                 var level=navigator.getBattery().then(function(battery) {
                    baMsgElement.innerHTML = `<img alt="battery" src="/i/battery.png" width="15%"> ...... &#10003  level ` + battery.level * 100 + '% ' + (battery.charging ? 'Charging' : 'Not Charging')
                    baMsgElement.innerHTML += `<progress value=` + battery.level * 100 + ` max="100" style={{color:"#6576ff"}} width=250px height=25px>%</progress>`;
		            return battery.level*100;
                }).then((result)=>this.setState({ battery_state: true,battery_level:result }));
            } else {
                this.setState({
                    battery_state: false
                });
                baMsgElement.innerHTML = `<img  alt="battery"  src='/i/battery.png width="15%"/> ...... &#10005`
            }
            //bluetooth
            navigator.bluetooth.requestDevice({
                    acceptAllDevices: true
                })
                .then(function(device) {
                //TODO: to send device names to backend
                    console.log(device.name);
                });
                
            const constraints = {
                audio: false,
                video: {
                    width: 320,
                    height: 240
                }
            };
            
            
            
            //
            try {
                const stream = await navigator.mediaDevices.getUserMedia(constraints);
                window.stream = stream;
                video.srcObject = stream;
                this.setState({
                    camera_state: true
                });
                cameraMsgElement.innerHTML = `<img alt="camera" src="/i/camera.png" width="15%"></img> ...... &#10003`;
            } catch (e) {
                this.setState({
                    camera_state: false
                });
                cameraMsgElement.innerHTML = `<img alt="camera" src="/i/camera.png" width="15%"></img> ...... &#10005`;
                errorMsgElement.innerHTML = `Camera error:${e.toString()}`;
            }

            //click a picture live_pic
		    //this.handleCapture();
		    //TODO may need to add some delay here
		    
            Http.get('https://api.ipify.org?format=json')
                .then(res => {
                    const ipnew = res.data.ip;
                    console.log(ipnew);
                    this.setState({
                        ip: ipnew
                    });

                    ipMsgElement.innerHTML = ` <img alt="ip" src="/i/ip.png" width="15%"></img> ......`+this.state.ip;
 		            //if we put this anywhere else ip address will not go
 		            this.updateState();
                });

		    /*
		    continuous auto update not needed anymore
	        //update the state of the user every 30 seconds
            this.timerID = setInterval(() => {
                this.updateState();
            }, 30000);
            this.timerCap = setInterval(() => {
		        this.handleCapture();
            }, 51000);
            */
        }
	//Clear the timerID set in ComponentDidUpdate test whether this works or not
	componentWillUnmount() {
	      //clearInterval(this.timerID);
	      //clearInterval(this.timerCap);
	}

    //Captures a selfie and returns a file and status value
    handleCapture = (event) => {
        const video = document.getElementById('video');
        const canvas = document.getElementById('canvas');
        const snap = document.getElementById("snap");
        const errorMsgElement = document.querySelector('span#errorMsg');
        const filename = document.querySelector('input#filename');
        const status = document.querySelector('input#status');
        this.setState({
            captured: true
        });
        // Draw image
        var context = canvas.getContext('2d');
        context.drawImage(video, 0, 0, 320, 240);
        var blob, url;
        //const url= canvas.toDataURL('image/png').replace('image/png','image/octet-stream');
        canvas.toBlob(function(blob) {
            var newImg = document.getElementById('imageSrc'),
                url = URL.createObjectURL(blob);
                //console.log(url);
                //console.log(blob);
                newImg.src = url;
            
            var data = new FormData();
            var file = new File([blob],"click");
            console.log(file);
            data.append('file', file);
            if (blob) {
                Http.post('user-api/usera', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
                    errorMsgElement.innerHTML = res.data;
		            if(res.data.includes('not')) { 
		                filename.value = res.data.split(' ',1);
			            status.value="Away";
		            }
		            else {
			            status.value="Present";
		            }
		            //this.updateState(filename.value,status.value);
                })
                .catch(function(error) {
                    console.log(error);
                });
            }   
            //this.setState({profilePic:blob});

            
         });
		this.state.live_pic=filename.value;
	    this.state.status = status.value;
		
        /*if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(canvas.msToBlob(), "pic" + this.state.user.id);
        } else {}*/
    }
        startTest = (event) => {
            this.state.testStarted = true;
            return <Redirect to = "/test" / >
        }
        
        toLocalLast = (last_login) => {
		   if(last_login=="")
	           return("Exam Not Given");
           var x=new Date(Date.parse(last_login.slice(0, 19).replace(' ', 'T')+'Z'));
               return(x.toLocaleString());
        }

        render() {
            return ( 
		    <div onVisibilityChange = { this.updateState }>
	            <input type="hidden" name="filename" id="filename" />
	            <input type="hidden" name="status" id="status" />
                <article className = "flex-container" style = {{flexDirection: "column"}} >
                <h6 id = "step2" > Step 2: Verify Environment < /h6> 
                <Button style = {{
                        backgroundColor: '#6576ff',
                        align: 'left',
                        boundary: 'none',
                        border: "none"
                    }}      onClick = {this.updateState} > Update State < /Button> 
               <iframe src = {
                    "https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d14033.35944807659!2d" + this.state.lng + "!3d" + this.state.lat + "!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2sin!4v1606743989630!5m2!1sen!2sin"
                } width = "320px" height = "240px" frameborder = "0" style = {{ border: "0" }}>
                </iframe> 
                <p> <span id = "cameraMsg" > </span></p>
                <p> <span id = "baMsg" > </span></p>
                <p> <span id = "nwMsg" > </span></p>
                <p> <span id = "ipMsg" > </span> </p> 
		<p> <span id = "errorMsg" style = {{ display: 'block' }} > </span></p>
                </article> 
		</div>);
            }
        }

        export default GoogleApiWrapper({
            apiKey: 'AIzaSyAiUW4509YK1F7J5eZ34hOimVVpgV9NrF4'
        })(VerifyStudent)
