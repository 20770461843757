import React, {
    Component,
    useState,
    useEffect
} from 'react'
import {
    createStore
} from 'redux'
import ReactDOM from 'react-dom'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Http from 'axios'
import {
    Redirect
} from 'react-router-dom'
import Profile from './Profile'
import Menu from '../Menu/Menu'
import NewDashboard from '../Dashboard/NewDashboard'
import AdminDashboard from '../Dashboard/AdminDashboard'
import ExamDashboard from '../Dashboard/ExamDashboard'
import './Auth.css'
import Card from 'react-bootstrap/Card'
import Base64js from 'base64-js'

/*

1. Show the login window
2. If user exists then send a message user joined to everyone

*/

class Login extends React.Component {
        constructor() {
            super();
            this.state = {
                status: false
            };
            //this.state={name:"She",email:"she@email.com",mobile:"1111122222"};
        }

	checkUserExists=()=> {
		    Http.get('/user/' + this.state.email + '/exists', {}, null, 'json')
		        .then(response=> {
				alert("User Exists");
				console.log("User Exists");
				this.getCredentials();
				            return true;
				        })
			.catch(error=> {
				alert("User does not Exist");
				console.log("User does not Exist");
				            return false;
				       });
	}

	getCredentials=()=> {
		    Http.get('/credential/' + this.state.email, {userVer:'discouraged',txAuthExtension:''}, null, 'json')
		        .then(response=> {
				            console.log(response)
				        })
			.catch(error=> {
				alert("Getting Credentials Failed");
				console.log("Getting Credentials Failed");
				       });
	}


        getAssertion=()=> {
	    Http.get('/user/' + this.state.email + '/exists', {}, null, 'json').then(response=> {
            console.log(response);
	     })
	     .then(()=> {
	
            //var user_verification = $('#select-verification').find(':selected').val();            
//            //var txAuthSimple_extension = $('#extension-input').val();
//
                        Http.get('/assertion/' + this.state.email + '?userVer=discouraged&txAuthExtension=', {
                                        userVer: 'discouraged',
                                        txAuthExtension: ''
                        }, null, 'json')
                        .then(makeAssertionOptions=> {
                              console.log("Assertion Options:");
                              console.log(makeAssertionOptions);
                              makeAssertionOptions.data.publicKey.challenge = this.bufferDecode(makeAssertionOptions.data.publicKey.challenge);
                              makeAssertionOptions.data.publicKey.allowCredentials.forEach(listItem=> {
                                                                                                              listItem.id = this.bufferDecode(listItem.id)
                                                                                                          });
                          console.log(makeAssertionOptions.data);
                          navigator.credentials.get({
                                   publicKey: makeAssertionOptions.data.publicKey
                                                                                                          })
                          .then(credential=> {
                          console.log(credential);
                          this.verifyAssertion(credential);
                        }).catch(err=> {
                           console.log(err.name);
		        });
	              });
	})
	.catch(error=> {
	if (!error.exists) {
	     alert("User not found, try registering one first!");
	}
	return;
	});
	}
        verifyAssertion=(assertedCredential)=> {
				    //       // Move data into Arrays incase it is super long
	    console.log('calling verify')
	    let authData = new Uint8Array(assertedCredential.response.authenticatorData);
	    let clientDataJSON = new Uint8Array(assertedCredential.response.clientDataJSON);
	    let rawId = new Uint8Array(assertedCredential.rawId);
	    let sig = new Uint8Array(assertedCredential.response.signature);
	    let userHandle = new Uint8Array(assertedCredential.response.userHandle);
	    Http.post( '/assertion',
		 {
		 id: assertedCredential.id,
		 rawId: this.bufferEncode(rawId),
		 type: assertedCredential.type,
		 response: {
		    authenticatorData: this.bufferEncode(authData),
		    clientDataJSON: this.bufferEncode(clientDataJSON),
		    signature: this.bufferEncode(sig),
		    userHandle: this.bufferEncode(userHandle),
		},
		})
		.then(response=>{
			console.log(response);
		})
		.catch(error => {
			console.log(error);
		});
		}
		

	 bufferEncode(value) {
           // return Buffer.from(value).toString('base64');
	     return Base64js.fromByteArray(value)
	        .replace(/\+/g, "-")
	        .replace(/\//g, "_")
	        .replace(/=/g, "");
	 }
	bufferDecode(value) {
	    return Uint8Array.from(atob(value), c => c.charCodeAt(0));
	}

        componentWillMount() {
            const user = JSON.parse(sessionStorage.getItem('user'));
            //const exam = JSON.parse(sessionStorage.getItem('exam'));
            this.state.user = user;
            //this.state.exam=exam;
            console.log(this.state.user);
            //console.log(this.state.exam);
            if (user) this.state.status = true;

        }

//standard function. Assign value to name
        handleChange = (event) => {
            let nam = event.target.name;
            let val = event.target.value;
            this.setState({
                [nam]: val
            });
        }
        
        

        handleSubmit = (event) => {
            event.preventDefault();
            const errorMsgElement = document.querySelector('span#errorMsg');
	
            const url='user-api/usere/' + this.state.email + '/' + this.state.password
            var config = {
                method: 'get',
                url: url,
                //timeout:100 
            };
            Http(config)
                .then(res => {
                    const response = res.data;
                    this.setState({
                        user: response
                    });
                    this.setState({
                        status: true
                    });
                    sessionStorage.setItem('user', JSON.stringify(response));
                    console.log(response);
                    //let userStore = createStore(this.state.user);
                    errorMsgElement.innerHTML = "Login Successful";
                                //opens chat for all students and lets them know

                    const bc = new BroadcastChannel('lims_channel');
                     bc.postMessage("joined");
                    //return <Redirect to="/profile" />
                })
                .catch(function(error) {
                    errorMsgElement.innerHTML = "Password did not match";
		            console.log(error);
                });
        }
       /* handleSubmit1 = (event) => {
            event.preventDefault();
            var config = {
                method: 'get',
                url: 'https://api.wit.ai/message?v=20200827&q=I%20am%20tired',
                headers: {
                    'Authorization': 'Bearer DDWMOBTNMSYZ7B7JHDLQ233BMLC7WXRG'
                }
            };

            Http(config)
                .then(function(response) {
                    console.log(JSON.stringify(response.data));
                })
                .catch(function(error) {
                    console.log(error);
                });
            var data = 'This is expected to be sent back as part of response body.';
        }*/
        render() {
                if (this.state.status === false) {
                    return ( 
			    
			                <div>
	    <Menu  dark="true" />
	    <br />
	    <br />
			    		<div className="row" >
			    		<div className="column side">
			    		</div>
			    		<div className="column middle">
		                        <div className="flex-container" style={{flexDirection:"row" }}>
                         		<Card>
                         		<Card.Body>
                         	        <img class="loginimg" src="/login.png" width="70%" class="flexItem" style={{paddingTop:'50px'}}></img>
			    		<p><span id="errorMsg">{this.props.message}</span></p>
			    		<div class="login">
			    		<form  onSubmit={this.handleSubmit}  class="flexItem" style={{paddingTop:"0px"}}>
			    		<h5><b>Login</b></h5>
			    		    <input type='email' placeholder='Enter email' name='email' onChange={this.handleChange} style={{marginBottom:'4px'}} required autoFocus></input>
			    		    <br></br>
			    		    <input type='password' placeholder='Enter password' name='password' onChange={this.handleChange} required></input>
			    		    <br></br>
			    		    <br></br>
			    		    <Button style={{backgroundColor:"#6576ff",paddingTop:"10px", alignSelf:"right",border:"none"}} variant="primary" type='submit' placeholder='Login' size="lg" onClick={this.getAssertion} required>Login</Button><br /><a style={{color:"#6576ff",alignSelf:"left"}} href="/reset">Forgot Password</a>
			    		</form>
			    		</div>
			    		</Card.Body>
			    		</Card>
			    		</div>
			    		</div>
			    		</div>
			    		</div>
                    );
                } else if (this.state.user.role == 'invig') {
                    return ( < NewDashboard user = {
                            this.state.user
                        }
                        />);
                    }
                    else if (this.state.user.role == 'admin') {
                        return ( < AdminDashboard / > );
                    } else {
                        return ( < ExamDashboard user = {
                                this.state.user
                            }
                            />);
                        }
                    }
                }
                export default Login
