import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Http from 'axios'
import Profile from './Profile'
import HowItWorks from '../Home/HowItWorks'
import Pricing from '../Home/Pricing'
import {Redirect} from 'react-router-dom'
import './Auth.css'
import Adapter from 'webrtc-adapter'
import ExamDashboard from '../Dashboard/ExamDashboard'
import Card from 'react-bootstrap/Card'

/*a 
 *
 *
 * */

class ParentAuth extends React.Component{
	constructor(){
		super();
		//cv=require('./opencv.js');
		this.state={file:"",first_name:"She",last_name:"",email:"she@email.com",mobile:"1111122222",id:1,password:"password",rePassword:"password",status:true,captured:false,otp:123456,displayToast:true};
		this.handleCapture=this.handleCapture.bind(this);
	}

	handleChange = (event)=>{
		let nam=event.target.name;
		let val=event.target.value;
		this.setState({[nam]:val});
	}
	handleSubmit = (event)=>{
		const x=new Date();
		event.preventDefault();
                const filename = document.querySelector('input#filename');
		if(filename.value)
		{
			this.state.file=filename.value;
			console.log(this.state.file);
		}
		if(this.state.password===this.state.rePassword)
		{
		        Http.post('user-api/user',{
		          "first_name":this.state.first_name,
		          "last_name":this.state.last_name,
		          "email":this.state.email,
		          "mobile":this.state.mobile,
		          "password":this.state.password,
			  "timezone":x.getTimezoneOffset(),
			  "last_login":x.toISOString().slice(0,19).replace('T','  '),
			  "class":1,
			  "school":1,
			  "lat":this.state.lat,
			  "lng":this.state.lng,
			  "invig_id":9999,
		          "role":'invig',
			  "reg_pic":this.state.file
		        }, {
		          headers: {
		            "Content-Type": "application/json"
		          }
		        })
		      .then(res => {
                      const response = res.data;
                      this.setState({ user:response,status:false});
		      sessionStorage.setItem('user',JSON.stringify(response)); 
	              return <Redirect to="/profile" />
                       })
                      .catch(function(error) {
                          const errorMsgElement = document.querySelector('span#errorMsg');
                          errorMsgElement.innerHTML = error;
		          console.log(error);
                      });
		}
		else alert("The passwords did not match");
	}
        showPosition = (position) => {
		this.state.lat=position.coords.latitude;
		this.state.lng=position.coords.longitude;
	}
                
                // Access webcam
        async componentDidMount() {
		const video = document.getElementById('video');
		const desktop = document.getElementById('desktop');
                const canvas = document.getElementById('canvas');
                const errorMsgElement = document.querySelector('span#errorMsg');
                const constraints = {
                    audio: false,
                    video: {
			    width: 320, height: 180,frameRate:1
                    }
                };
                    try {
                        const stream = await navigator.mediaDevices.getUserMedia(constraints)||navigator.webkitGetUserMedia ||
				    navigator.mozGetUserMedia;;
                        window.stream = stream;
                        video.srcObject = stream;
                        /*const deskstream = await navigator.mediaDevices.getDisplayMedia(constraints)||navigator.webkitGetDesktopMedia ||
				    navigator.mozGetDesktopMedia;;
                        window.stream = deskstream;
			console.log(deskstream);
                        desktop.srcObject = deskstream;*/
                    } catch (e) {
                        errorMsgElement.innerHTML = `navigator.mediaDevices.getUserMedia error:${e.toString()}`;
                    }
                    navigator.geolocation.getCurrentPosition(this.showPosition);
		//this.handleSetup();
        }
/*	handleSetup = (event) => {
		// handles JSON.stringify/parse
		 //const signaling = new SignalingChannel();
		 const constraints = {audio: true, video: true};
		 const configuration = {iceServers: [{urls: 'stuns:stun.example.org'}]};
		 const pc = new RTCPeerConnection(configuration);
		
		 // send any ice candidates to the other peer
		// pc.onicecandidate = ({candidate}) => signaling.send({candidate});

		
		 // let the "negotiationneeded" event trigger offer generation
		 pc.onnegotiationneeded = async () => {
		   try {
		       await pc.setLocalDescription(await pc.createOffer());
		     // send the offer to the other peer
		       //signaling.send({desc: pc.localDescription});
		       } catch (err) {
		       console.error(err);
		     }
		 };
	
           // once remote track media arrives, show it in remote video element
		  pc.ontrack = (event) => {
		                         // don't set srcObject again if it is already set.
		                           /*if (remoteView.srcObject) return;
		                             remoteView.srcObject = event.streams[0];
		                             };
		                             // call start() to initiate
		                             async function start() {
		                             try {
		                               // get local stream, show it in self-view and add it to be sent
		                                 const stream =
		                                 await navigator.mediaDevices.getUserMedia(constraints);
		                                 stream.getTracks().forEach((track) =>
		                                 pc.addTrack(track, stream));
		                                 selfView.srcObject = stream;
		                               } catch (err) {
		                                   console.error(err);
		                               }
		                             }

		                         //signaling.onmessage = async ({desc, candidate}) => {
		                         try {
		                           if (desc) {
		                           // if we get an offer, we need to reply with an answer
		                             if (desc.type === 'offer') {
		                               await pc.setRemoteDescription(desc);
		                               const stream =
		                               await navigator.mediaDevices.getUserMedia(constraints);
		                               stream.getTracks().forEach((track) =>
		                               pc.addTrack(track, stream));
		                               await pc.setLocalDescription(await pc.createAnswer());
		                               //signaling.send({desc: pc.localDescription});
		                           } else if (desc.type === 'answer') {
		                           await pc.setRemoteDescription(desc);
		                           } else {
		                           console.log('Unsupported SDP type.');
		                           }
		                         } else if (candidate) {
		                         await pc.addIceCandidate(candidate);
		                         }
		                       } catch (err) {
		                         console.error(err);
		                       }
		                       };
	}
	async saveFile=()=> {

		  // create a new handle
	   const newHandle = await window.showSaveFilePicker();
	
           //create a FileSystemWritableFileStream to write to
	   const writableStream = await newHandle.createWritable();
	         // write our file
	   await writableStream.write(imgBlob);
		
	         // close the file and write the contents to disk.
	   await writableStream.close();
        }
	 let saveImage = (img_name) => {
		         }
		
	*/
	handleCapture = (event) => {
		const video = document.getElementById('video');
                const canvas = document.getElementById('canvas');
                const snap = document.getElementById("snap");
                const errorMsgElement = document.querySelector('span#errorMsg');
                const filename = document.querySelector('input#filename');
                
		this.setState({captured:true});
                var context = canvas.getContext('2d');
                    context.drawImage(video, 0, 0, 320, 240);
            canvas.toBlob(function(blob) {
            var newImg = document.getElementById('imageSrc'),
                url = URL.createObjectURL(blob);
            newImg.src = url;
            
            var data = new FormData();
            var file = new File([blob],"selfie");
            data.append('file', file);
            if (blob) {
                errorMsgElement.innerHTML = "Wait as we find whether you are a human ....";
                Http.post('user-api/usera', data, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
                .then(res => {
		    if(res.data.includes('not'))
		    setTimeout(() => {errorMsgElement.innerHTML = "Ensure that your face is visible on camera"; }, 2000);
		    else
		    setTimeout(() => {errorMsgElement.innerHTML = "Fill the form to Register"; }, 2000);
                })
                .catch(function(error) {
                    console.log(error);
                    errorMsgElement.innerHTML = error;
                });
            }   
            this.setState({profilePic:blob});

            
            
         });
		if(errorMsgElement.innerHTML.includes("Ensure"))
		{
		    this.setState({captured:false});
		    console.log("Modifying state");
		}
		else
		{
		    this.setState({captured:true});
		}
		if(filename.value)
		{
			this.setState({file:filename.value});
			console.log(this.state.file);
		}
        if (window.navigator.msSaveBlob) {
            window.navigator.msSaveBlob(canvas.msToBlob(), "pic" + this.state.user.id);
        } else {}
                //saveFile(imageData);
	/*var data =new FormData();
		data.append('file',"selfie.png");
		console.log(data);
		this.setState({fileLivePic:"selfie.png"});
		console.log(this.state.fileLivePic);
	        Http.post('user-api/usera',data,{
		        headers: {
		          'Content-Type': 'multipart/form-data'
		        }
		    })
	        .then(res => {
			errorMsgElement.innerHTML=res.data;
		})
                .catch(function (error) {
                     console.log(error);
                 });

		dataURL.replace(/^data:image\/(png|jpg);base64,/, "")
		var imageData=btoa(dataURL);
                //saveFile(imageData);

		let src = window.cv.imread('canvas');
		let dst = new window.cv.Mat();
		// You can try more different parameters
		window.cv.cvtColor(src, dst, window.cv.COLOR_RGBA2GRAY, 0);
		window.cv.imshow('canvas', dst);
		src.delete(); dst.delete();
		//
*/
	}

	render(){
		if(this.state.status)
		{
	return(
		<div>
		<meta name="viewport" content="width=device-width,initial-scale=1.0" />
		<div style={{display:sessionStorage.getItem('paid')?"block":"block"}}>
		<h3 id="signup"><b>Parent Sign Up</b></h3>
		<div className="row invite" style={{backgroundColor:"#f8faf7"}}>
		<div className="column side">
		</div>
		<div className="column middle" >
		<div class="toast show" role="alert" aria-live="assertive" aria-atomic="true" style={{display:this.state.displayToast?"block":"none"}}>
		<div class="toast-header">
		<strong class="me-auto">Face Detection</strong>
		<small>0 mins ago</small>
		<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={()=>this.setState({displayToast:false})}></button>
		</div><div class="toast-body">
		  <p><span id="errorMsg">Click a picture</span></p>
		</div></div>
		<div className="flex-container">
		<Card style={{padding:"10px",margin:"10px",backgroundColor:"#f5f5f5"}} >
		<Card.Title>Capture Image</Card.Title>
		<Card.Body>
                <div className="video-wrap">
                    <video id="video" width="320px" height="240px" style={{display:this.state.captured?"none":"block"}} playsInline autoPlay></video>
		    <img id="imageSrc"  width="320px" height="240px" style={{display:this.state.captured?"block":"none"}}/>
                    <canvas id="canvas" width="320px" height="240px" style={{display:this.state.captured?"none":"none"}}></canvas>
		    <input type="hidden" name="filename" id="filename" />
                </div>
                <div className="controller">
		    <Button id="snap" autoFocus style={{backgroundColor:"#6576ff",paddingTop:"10px", border:"none",margin:'2px'}} variant="primary" type='submit' placeholder='Register' size="md" required onClick={this.handleCapture}>Capture</Button>
                </div>
		</Card.Body>
		</Card>
		<Card style={{padding:"10px",margin:"10px",backgroundColor:"#f5f5f5",display:this.state.captured?"block":"none"}} >
		<Card.Title>Sign Up</Card.Title>
		<Card.Body>
		<form onSubmit={this.handleSubmit} style={{width:'100%',margin:'2px'}} >
		<div className="flex-container" style={{flexDirection:"column" }}>
		<div className="flex-container" style={{flexDirection:"row" }}>
		    <input type='text' placeholder='First Name' name='first_name' onChange={this.handleChange} className="" required style={{margin:'2px',width:"45%"}} ></input>
		    <input type='text' placeholder='Last Name' name='last_name' onChange={this.handleChange} className="" required style={{margin:'2px',width:"45%"}}></input>
		</div>
		<div className="flex-container" style={{flexDirection:"column" }}>
		    <input type='text' placeholder='email' name='email' onChange={this.handleChange}  className="flex-item" required style={{margin:'4px',width:'90%'}}></input>
		    <input type='text' placeholder='mobile' name='mobile' onChange={this.handleChange} required  className="flex-item" style={{margin:'4px',width:'90%'}}></input>
		</div>
		<div className="flex-container" style={{flexDirection:"row" }}>
		    <input type='password' placeholder='password' name='password' onChange={this.handleChange} required  className="" style={{margin:'2px',width:'45%'}}></input>
		    <input type='password' placeholder='Re-enter password' name='rePassword' onChange={this.handleChange}  className="" required style={{margin:'2px',width:'45%'}}></input>
		</div>
		<div className="flex-container" style={{flexDirection:"row" }}>
		    <input type='checkbox'  className="" placeholder='' id="savePassword" name='savePassword' onChange={this.handleChecked} defaultChecked={this.state.checked} style={{margin:'2px',width:'20%'}}></input><label for="savePassword" style={{color:'#444',fontSize:"12px"}}>I agree to the <a href="/terms" style={{color:'#444'}}>Terms </a> and <a href="/privacy" style={{color:'#444'}}>Privacy Policy</a></label>
		</div>
		<Button disabled={!this.state.captured} style={{backgroundColor:"#6576ff",paddingTop:"10px", align:"right",border:"none",margin:'2px',width:"70%"}}  className="" variant="primary" type='submit' placeholder='Register' size="lg" required>Register</Button>
		</div>
		</form>
		</Card.Body>
		</Card>
		</div>
		</div>
		</div>
		</div>
		</div>
	);
	} else{
		return (<ExamDashboard user={this.state.user} />);
	}
	}
}

export default ParentAuth
