import React, {Component} from 'react'
import ReactDOM from 'react-dom'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Http from 'axios'
import Profile from '../Auth/Profile'
import Login from '../Auth/Login'
import HowItWorks from '../Home/HowItWorks'
import ExamDashboard from '../Dashboard/ExamDashboard'
import Button from 'react-bootstrap/Button'
import {Redirect} from 'react-router-dom'
import Card from 'react-bootstrap/Card'
import LocalPDFFilePreview from './LocalPDFFilePreview'  
import ViewSDKClient from "./ViewSDKClient";
/* Exam Creation process */
class Form extends React.Component{
	constructor(){
		super();
		this.state={ isLogged:false,version:'1.01',checked:true,inputDate:'text',inputTime:'text',last_name:"",email:"she@email.com",mobile:"1111122222",id:1,password:"password",rePassword:"re-",status:true,dateSet:false, camera_stateR:true, network_stateR:true, battery_stateR:true, ipR:true, latR:true};
		this.viewSDKClient = new ViewSDKClient();
		
	}
	    /* Helper function to check if selected file is PDF or not. */
	    isValidPDF = file => {
		            if (file.type === "application/pdf") {
				                return true;
				            }
		            if (file.type === "" && file.name) {
				                const fileName = file.name;
				                const lastDotIndex = fileName.lastIndexOf(".");
				                if (lastDotIndex === -1 || fileName.substr(lastDotIndex).toUpperCase() !== "PDF") return false;
				                return true;
				            }
		            return false;
		        };

	    /* Helper function to be executed on file upload
	     *     * for creating Promise which resolve to ArrayBuffer of file data.
	     *         **/
	    onFileUpload = event => {
		            event.persist();
		            this.viewSDKClient.ready().then(() => {
				                const files = event.target.files;
				                if (files.length > 0 && this.isValidPDF(files[0])) {
							 const fileName = files[0].name;
							 const reader = new FileReader();
							 reader.onloadend = e => {
							 const filePromise = Promise.resolve(e.target.result);
							 /* Helper function to render the file using PDF Embed API. */
							 this.viewSDKClient.previewFileUsingFilePromise("pdf-div", filePromise, fileName);
							};
							reader.readAsArrayBuffer(files[0]);
						 }
				            });
		          this.uploadQuestionPaper(event);
	    }

	handleChange = (event)=>{
                const errorMsgElement = document.querySelector('span#errorMsg');
		let nam=event.target.name;
		let val=event.target.value;
		this.setState({[nam]:val});
		errorMsgElement.innerHTML=val;
		if(nam=="date")
		{
			this.setState({dateSet:true});
		}
	}
	handleChecked = (event)=>{
		let nam=event.target.name;
		this.setState({[nam]:!this.state.checked});
	}
        uploadQuestionPaper=(e)=>{
		var data=new FormData();
                const errorMsgElement = document.querySelector('span#errorMsg');
		data.append('file',e.target.files[0]);
		console.log(e.target.files[0]);
		this.setState({fileQuestionPaper:URL.createObjectURL(e.target.files[0])});
		console.log(this.state.fileQuestionPaper);
	       Http.post('exam-api/examq',data,{
		        headers: {
		          'Content-Type': 'multipart/form-data'
		        }
		    })
	        .then(res => {
			errorMsgElement.innerHTML=res.data;
			this.setState({link:res.data});
		})
                .catch(function (error) {
                     console.log(error);
                 });
	}
	async componentWillMount() {
		if(this.state.user=JSON.parse(sessionStorage.getItem('user')))
                    this.state.isLogged=true;
		else{
	            console.log("User not Logged in");
                    this.state.isLogged=false;
		}
	}
	handleSubmit = (event)=>{
		event.preventDefault();
                const errorMsgElement = document.querySelector('span#errorMsg');


		//Convert the google pdf link to google preview link
                if(this.state.platform =="GoogleDoc") {
		if(this.state.link.lastIndexOf("/view")==this.state.link.indexOf("/view")) 
			this.state.link.replace("/view","/preview");
		}

		if(this.state.dateSet)
		{
		    var x=new Date(Date.parse(this.state.date)+Date.parse("1970-01-01T"+this.state.start_time+"Z"));
		    var x=new Date(Date.parse(this.state.date+"T"+this.state.start_time+"Z"));
		    var y=new Date(x.getTime()+x.getTimezoneOffset()*60000);
		    this.state.date=y.toISOString();
		    let time=this.state.date.split("T");
		    this.state.date=time[0];
		    time=time[1].split(".");
		    this.state.start_time=time[0];
		console.log(this.state.start_time);
		console.log(this.state.date);

		Http.post('exam-api/exam',{
		          "name":this.state.name,
		          "platform":this.state.platform,
		          "date":this.state.date,
		          "start_time":this.state.start_time,
		          "duration":this.state.duration,
		          "link":this.state.link,
		          "camera_stateR":this.state.camera_stateR,
		          "network_stateR":this.state.network_stateR,
		          "battery_stateR":this.state.battery_stateR,
		          "ipR":this.state.ipR,
		          "latR":this.state.latR,
			  "invig_id":this.state.user.id,
		        }, {
		          headers: {
		            "Content-Type": "application/json"
		          }
		        })
		.then(res => {
                      const response = res.data;
                      this.setState({ exam:response,status:false});
		      console.log("Exam addition successful");
		      errorMsgElement.innerHTML="Exam Scheduled Successfully";
	              return <Redirect to="/examdash" />
                })
                .catch(function (error) {
                  this.setState({ status:true});
                  console.log(error);
		  errorMsgElement.innerHTML=error;
                });
		} else {
		Http.post('exam-api/exam',{
		          "name":this.state.name,
		          "platform":this.state.platform,
		          "duration":this.state.duration,
		          "link":this.state.link,
		          "camera_stateR":this.state.camera_stateR,
		          "network_stateR":this.state.network_stateR,
		          "battery_stateR":this.state.battery_stateR,
		          "ipR":this.state.ipR,
		          "latR":this.state.latR,
			  "invig_id":this.state.user.id,
		        }, {
		          headers: {
		            "Content-Type": "application/json"
		          }
		        })
		.then(res => {
                      const response = res.data;
                      this.setState({ exam:response,status:false});
		      console.log("Exam addition successful");
		      errorMsgElement.innerHTML="Exam Scheduled Successfully";
	              return <Redirect to="/examdash" />
                })
                .catch(function (error) {
                  this.setState({ status:true});
                  console.log(error);
		  errorMsgElement.innerHTML=error;
                });
		} 
	}
	render(){
		if(this.state.isLogged)
		{
		if(this.state.status)
		{
	return(
<div>
<nav className="navbar">
  <nav className="navbar-brand">
           	        	<h3><a style={{color:"#fafafa"}} href="/"><img alt="monitorexam" src="/monitorexam_t.png" width="213px" height="120px"></img></a></h3>
  </nav>
  <nav className="navbar appmenu right">
    <a className="nav-link" style={{color:"#00b3b3"}} href="/examdash">Exam Dashboard</a>
    <a className="nav-link" style={{display:this.state.user.role=='invig'?'block':'none',color:"#00b3b3"}} href="/dashboard">Student Dashboard</a>
    <a className="nav-link" href="/login" style={{backgroundColor:"#00b3b3",color:"white",borderRadius:"30px"}} onClick={()=>sessionStorage.clear()}>Logout</a>
  </nav>
</nav>
	        <br />
	        <br />
	        <br />
	        <br />
			    		<div className="row invite" id="createexam">
			    		<div className="column side">
			    		</div>
			    		<div className="column middle">
		<p><span id="errorMsg"></span></p>
		<p>Version {this.state.version}</p>
		<section style={{width:'100%',float:'left'}}>
		<Card style={{padding:"20px",margin:"10px"}} >
		<Card.Title style={{textAlign:"center"}}>Step 2: Create Exam</Card.Title>
		<Card.Body>
		<form onSubmit={this.handleSubmit}  className="flex-container" style={{width:'100%',margin:'2px'}} autocomplete="on">
		    <input type='text'  className="flex-item"  placeholder='Enter Exam Name' name='name' onChange={this.handleChange} required style={{margin:'2px'}} autoFocus></input>
		<div style={{borderStyle:"solid",borderColor:"#ccc",borderWidth:"1px",borderRadius:"8px"}}>
		<select type='text' className="flex-item" placeholder='Enter Platform' name='platform' onClick={this.handleChange} onChange={this.handleChange} required style={{margin:'2px',border:"2px"}}>
		<option value="">Please select exam type</option>
	        <option value="Offline">PDF Document</option>
		<option value="GooglePDF">Google Drive PDF</option>
		<option value="Google">Google Form</option>
		<option value="Microsoft">Microsoft</option>
		<option value="Quizizz">Quizizz</option>
		<option value="Other">Other</option>
		</select>
		</div>
		    <input type='url' id="testlink" className="flex-item"  placeholder='Enter Link' name='link' onChange={this.handleChange} style={{margin:'2px',display:this.state.platform=="Offline"?"none":"block"}}></input>
				                <div id="pdf-div" className="full-window-div"  style={{border:'1px',margin:'2px',display:this.state.platform=="Offline"?"block":"none"}}>
				                    <div style={{ borderStyle:"outset",borderRadius:"30px",borderColor:"#ccc",borderWidth:"1px",margin: "auto",textAlign:"center" }}>
				                        <label htmlFor="file-picker" className="file-picker">Click Here to Upload Question Paper(PDF)</label>
				                        {/* Listen for file upload */}
				                        <input onChange={ this.onFileUpload } type="file" id="file-picker" name="file-picker"
				                            accept="application/pdf" style={{display:this.state.platform=="Offline"?"block":"none"}} className="file-input"/>
				                    </div>
				                </div>
		<label className="button" id="snap" for="upload" style={{textAlign:"center",backgroundColor:"#00b3b3",color:"white", borderRadius:"30px",paddingTop:"10px", align:"right",margin:'2px',padding: ".375rem", fontSize: "1rem", lineHeight: "1.5",display:this.state.platform=="Offline"?"none":"none"}} size="md"  required>Upload Question Paper</label><input id="upload" type='file' style={{display:this.state.platform=="Offline"?"block":"none"}} name='fileQP' onChange={this.uploadQuestionPaper}/>
		    <input type={this.state.inputDate} className="flex-item" placeholder='Enter Date' name='date' onFocus={()=>this.setState({inputDate:'date'})} onChange={this.handleChange} style={{margin:'2px'}}></input>
		    <input type={this.state.inputTime} className="flex-item" placeholder='Enter Start Time' name='start_time' onFocus={()=>this.setState({inputTime:'time'})} onChange={this.handleChange} style={{margin:'2px'}}></input>
		    <input type='text' className="flex-item" placeholder='Enter Duration in minutes' name='duration' onChange={this.handleChange} required style={{margin:'2px'}}></input>
		<br />
		<div className="flex-item">
		           	   <img alt="camera" src="/i/camera.png" width="15%"></img><p>  </p>
		           	   <img alt="location" src="/i/location.png" width="15%"></img><p>  </p>
		           	   <img alt="ip" src="/i/ip.png" width="15%"></img><p>  </p>
		           	   <img alt="battery" src="/i/battery.png" width="15%"></img><p>  </p>
		           	   <img alt="network" src="/i/network.png" width="15%"></img>
		           	   </div>
		           	   <br />
		           	   <div style={{display:"none"}}>
		<div className="flex-item">
		    <input type='checkbox' placeholder='' id="camera_stateR" name='camera_stateR' onChange={this.handleChecked} defaultChecked={this.state.checked} style={{margin:'2px'}}></input><label for="camera_stateR">Camera</label>
		</div>
		<div className="flex-item ">
		    <input type='checkbox' name='network_stateR' onChange={this.handleChecked} defaultChecked={this.state.checked} style={{margin:'2px'}}></input><label for="network_stateR">Network</label>
		</div>
		<div className="flex-item ">
		    <input type='checkbox' name='battery_stateR' onChange={this.handleChecked} defaultChecked={this.state.checked} style={{margin:'2px'}}></input><label for="battery_stateR">Battery</label>
		</div>
		<div className="flex-item ">
		    <input type='checkbox' placeholder='' name='ip_usedR' onChange={this.handleChecked} defaultChecked={this.state.checked} style={{margin:'2px'}}></input><label for="ip_usedR">IP</label>
		</div>
		<div className="flex-item ">
		    <input type='checkbox' placeholder='' name='latR' onChange={this.handleChecked} defaultChecked={this.state.checked} style={{margin:'2px'}}></input><label for="latR">Location</label>
		</div>
		           	   </div>
		<br />
		<div>
		    <Button style={{backgroundColor:"#00b3b3",paddingTop:"10px", align:"right",border:"none",margin:'2px'}} variant="primary" type='submit' placeholder='Add Exam' size="lg" required>Add Exam</Button>
		</div>
		</form>
		</Card.Body>
		</Card>
		</section>
	</div>
	</div>
	</div>
	);
	}
	else
	    return (<ExamDashboard />);
	}
	else
	    return(<Login />);
    }
}

export default Form
