import React, { useState, useEffect } from 'react';
import PubNub from 'pubnub';
import { PubNubProvider, usePubNub } from 'pubnub-react';

// Chat from student to examiner
// channel U_monitorexam_com 2 way communication
// channel A_monitorexam_com 1 way communication from invigilator to student
function PubChat() {
	      const pubnub = usePubNub();
	      const [messages, addMessage] = useState([]);
	      const [message, setMessage] = useState('');
	      const user_id=JSON.parse(sessionStorage.getItem('user')).id;
	      const invig_id=JSON.parse(sessionStorage.getItem('user')).invig_id;
	      //user channel
	      const [channels]= useState(['U_monitorexam_com'+'.'+invig_id+'.'+user_id]);
	      
	      //announcement channel
	      const [achannel]= useState(['A_monitorexam_com'+'.'+invig_id]);


              //receive message in the box
	      const handleMessage = event => {
		    const message = event.message;
		    if (typeof message === 'string' || message.hasOwnProperty('text')) {
		       var text = message.text || message;
		       const sender_id=event.publisher.slice(0,event.publisher.length-15);
			     text = event.publisher.slice(0,event.publisher.length-15) + ":"+text;
		       addMessage(messages => [...messages, text]);
		    }
	      }; 

	      //send message to a channel and clean the box
	      const sendMessage = message => {
		    if (message) {
		          pubnub
		             .publish({ channel: channels[0], message })
		             .then(() => setMessage(''));
		             }
		    };

	      useEffect(() => {
		              pubnub.addListener({ message: handleMessage });
		              pubnub.subscribe({ channels });
		              pubnub.subscribe({ channels: achannel });
		            },[channels]);

	      return (
		              <div id="chat-bot"> {/*style={pageStyles}*/}
          {/* StyleSheets  */}
		                <div className="messenger br0 expanded" >{/*style={chatStyles}*/}
		                  <div className="chatroom"> {/*style={headerStyles}*/}
                           <span className="text-center mb-2"><em class="icon ni ni-chat-circle" style={{fontSize:"30px",color:"#6576ff"}}></em><p>Chat with Proctor</p></span>
		                  </div>
		                  <div class="timestamp">{new Date().toLocaleTimeString()}</div>
					            {messages.map((message, index) => {
							          return (
				                      <div className={(message.split(":")[0]==invig_id)?"msg msg-right":"msg msg-left"}>
				                        <div className="bubble" >{/*style={listStyles}*/}
		        	                    <div key={`message-${index}`}>
		                                 <strong>{(message.split(":")[0]==invig_id)?"Proctor: ":"Student: "}</strong>
                                       {message.split(":")[1]}
				                          </div>
				                        </div>
				                      </div>
				               );
				              })}
				            <div className="type-area">{/*style={footerStyles}*/}
				            <input type="text" placeholder="Type Here..." value={message} className="typing" onKeyPress={e => {
				               if (e.key !== 'Enter') return;
					             sendMessage(message);
				             }}
				             onChange={e => setMessage(e.target.value)}
				            />
					{/*style={inputStyles}*/}
					{/*<button style={buttonStyles} onClick={e => { e.preventDefault(); sendMessage(message); }}><em class="icon ni ni-plus"></em> Send Message </button>*/}
                    <span className="send"><em onClick={e => {
                      e.preventDefault();
                      sendMessage(message);
                    }} 
                    className="icon ni ni-send" style={{background:"#6576ff"}}></em> 
                    </span>
				            </div>
		                </div>
		                <div class="icon expanded d-none">
                      <div class="user">
                        <i class="ni ni-user-circle"></i>
		                      {JSON.parse(sessionStorage.getItem('user')).first_name}
		                      {JSON.parse(sessionStorage.getItem('user')).id}
                      </div>
                      <i class="ni ni-chat-circle"></i>
                    </div>
		              </div>
		            );
	    }

    const pageStyles = {
	          alignItems: 'center',
	          borderRadius: '5px',
	          display: 'flex',
	          justifyContent: 'center',
	          minHeight: '40vh',
	        };

    const chatStyles = {
	          display: 'flex',
	          flexDirection: 'column',
	          height: '25vh',
	          borderRadius: '5px',
	        };

    const headerStyles = {
	          background: '#00b3b3',
	          borderRadius: '5px',
	          color: 'white',
	          fontSize: '1.4rem',
	          padding: '10px 15px',
	        };

    const listStyles = {
	          alignItems: 'flex-start',
	          backgroundColor: '#eee',
	          borderRadius: '5px',
	          display: 'flex',
	          flexDirection: 'column',
	          flexGrow: 1,
	          overflow: 'auto',
	          padding: '10px',
	        };

    const messageStyles = {
	          backgroundColor: '#ddd',
	          borderRadius: '5px',
	          color: '#222',
	          fontSize: '1.1rem',
	          margin: '5px',
	          padding: '8px 15px',
	        };

    const footerStyles = {
	          display: 'flex',
	        };

    const inputStyles = {
	          flexGrow: 1,
	          fontSize: '1.1rem',
	          padding: '5px 5px',
	        };

    const buttonStyles = {
	          fontSize: '1.1rem',
	          borderRadius: '5px',
	          padding: '10px 15px',
	          background: '#00b3b3',
	          color:"white",
	          border:"none"
	        };

    export default PubChat
