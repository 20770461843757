import React, {
    Component,
    useState,
    useEffect
} from 'react'
import {
    createStore
} from 'redux'
import ReactDOM from 'react-dom'
import Alert from 'react-bootstrap/Alert'
import Button from 'react-bootstrap/Button'
import Jumbotron from 'react-bootstrap/Jumbotron'
import Http from 'axios'
import {
    Redirect
} from 'react-router-dom'
import Profile from './Profile'
import Login from './Login'
import './Auth.css'
import Card from 'react-bootstrap/Card'
import Menu from '../Menu/Menu'

class ResetPassword extends React.Component {
    constructor() {
        super();
        this.state = {
		status: false,displayToast:true,enterOtp:false,changePassword:false
        };
        //this.state={name:"She",email:"she@email.com",mobile:"1111122222"};
    }
    componentWillMount() {
        const user = JSON.parse(sessionStorage.getItem('user'));
        //const exam = JSON.parse(sessionStorage.getItem('exam'));
        this.state.user = user;
        //this.state.exam=exam;
        //console.log(this.state.exam);
        if (user) this.state.status = true;
    }

    handleChange = (event) => {
        let nam = event.target.name;
        let val = event.target.value;
        this.setState({
            [nam]: val
        });
    }
    SendOtp = () => {
        Http.put('user-api/usero/'+this.state.email)
	    .then(res=>{
                    const response = res.data;
                          const errorMsgElement = document.querySelector('span#errorMsg');
                          errorMsgElement.innerHTML = response;
		          console.log("OTP Sent");
		    this.setState({enterOtp:true});

	    })
                      .catch(function(error) {
                          const errorMsgElement = document.querySelector('span#errorMsg');
                          if(error.message.includes("401"))
                          {
                                errorMsgElement.innerHTML = "Incorrect email";
                          }
                          else {
                                errorMsgElement.innerHTML = error;
                          }
		                  console.log(error);
                      });
		//+ this.state.id, {
    }
    VerifyOtp = () => {
                          const errorMsgElement = document.querySelector('span#errorMsg');
                          errorMsgElement.innerHTML = "Verifying";
        Http.get('user-api/userv/' + this.state.email + '/' + this.state.otp)
                .then(res => {
                    const response = res.data;
                          const errorMsgElement = document.querySelector('span#errorMsg');
                    this.setState({
                        user: response
                    });
                          errorMsgElement.innerHTML = "OTP Verified";
		          console.log("OTP Verified");
		    this.setState({changePassword:true});
		})
                      .catch(function(error) {
                          const errorMsgElement = document.querySelector('span#errorMsg');
                          errorMsgElement.innerHTML = "OTP Verification failed";
		                  console.log(error);
		                  this.setState({changePassword:true});
                      });
		//+ this.state.id, {
    }
    handleSubmit = (event) => {
        event.preventDefault();
            const errorMsgElement = document.querySelector('span#errorMsg');
            /*Http.get('user-api/usere/' + this.state.email + '/' + this.state.otp)
                .then(res => {
                    const response = res.data;
                    this.setState({
                        user: response
                    });
                    this.setState({
                        status: true
                    });
		});*/
        var password;
        password = this.state.password;
        Http.defaults.headers.common['Authorization'] = this.state.user.token;
        Http.put('user-api/user/' + this.state.user.id, {
                "password": this.state.password,
            }, {
                headers: {
                    "Content-Type": "application/json"
                }
            })
            .then(res => {
                const response = res.data;
                this.setState({
                    user: response
                });
                this.setState({
                    status: true
                });
                sessionStorage.setItem('user', JSON.stringify(response));
                const errorMsgElement = document.querySelector('span#errorMsg');
                errorMsgElement.innerHTML = "Password changed Successfully";
                //let userStore = createStore(this.state.user);
                //return <Redirect to="/profile" />
            })
            .catch(function(error) {
                const errorMsgElement = document.querySelector('span#errorMsg');
                errorMsgElement.innerHTML = error;
                console.log(error);
            });
    }
    render() {
        if (this.state.status === false) {
            return ( 
			<div>
	    <Menu  dark="true" />
	    <br />
	    <br />
			    		<div className="row invite py-5" >
			    		<div className="column side">
			    		</div>
			    		<div className="column middle">
		                        <div className="flex-container" style={{flexDirection:"row" }}>
		        <Card>
		        <Card.Title>Forgot Password</Card.Title>
		        <Card.Body>
                         	        <img src="/forgot.png" width="90%" height="90%" style={{paddingTop:'20px'}}></img>
		<div class="toast show" role="alert" aria-live="assertive" aria-atomic="true" style={{display:this.state.displayToast?"block":"none"}}>
		<div class="toast-header">
		<strong class="me-auto">Reset Password</strong>
		<button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close" onClick={()=>this.setState({displayToast:false})}></button>
		</div><div class="toast-body">
		  <p><span id="errorMsg">Enter Email</span></p>
		</div></div>
		    	<form onSubmit={this.handleSubmit} class="login" style={{paddingTop:"10px"}}>
		    	    <input class="login" type='email' placeholder='Enter email' name='email' onChange={this.handleChange} style={{marginBottom:'4px',width:"55%"}} required ></input>
		    	    <Button onClick={this.SendOtp} style={{backgroundColor:"#6576ff",paddingTop:"10px", alignSelf:"right",border:"none",display:"block"}}>Send OTP</Button>
		    	    <input class="login" type='text' placeholder='Enter OTP' name='otp' onChange={this.handleChange} style={{marginBottom:'4px',width:"55%",display:this.state.enterOtp?"block":"none"}} required></input>
		    	    <Button onClick={this.VerifyOtp} style={{backgroundColor:"#6576ff",paddingTop:"10px", alignSelf:"right",border:"none",display:this.state.enterOtp?"block":"none"}}>Verify OTP</Button>
		            <br></br>
		    	    <input class="login" type='password' placeholder='Enter New Password' name='password' onChange={this.handleChange} style={{marginBottom:'4px',width:"66%",display:this.state.changePassword?"block":"none"}} required></input>
		    	    <br></br>
		    	    <input class="login" type='password' placeholder='Re-Enter password' name='repass' onChange={this.handleChange} style={{width:"66%",display:this.state.changePassword?"block":"none"}}required></input>
		    	    <br></br>
		    	    <br></br>
		    	    <Button style={{backgroundColor:"#6576ff",paddingTop:"10px", alignSelf:"right",border:"none"}} variant="primary" type='submit' placeholder='Reset Password' size="lg" required>Reset Password</Button>
		    	</form>
		        </Card.Body>
		        </Card>
		    	</div>
		    	</div>
		    	</div>
		    	</div>
            );
        } else {
            return ( < Login message="Password Reset Successful"/ > );
        }
    }
}



export default ResetPassword
